import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FiBatteryCharging} from "react-icons/fi";
import {BsPaintBucket} from "react-icons/bs";
import DisplayValue from "./DisplayValue";
import TextInputFormComponent from "../ERPImport/TextInputFormComponent";
import {PubSub} from "@aws-amplify/pubsub";
import TextInput from "../ERPImport/TextInput";
import {TbHandStop} from "react-icons/tb";
import {IoMdClose} from "react-icons/io";

class StorageController extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            power: 200,
            duration: 5,
            commandType: ""

        }

    }

    async startAction() {
        let {deviceName, deviceId} = this.props

        await PubSub.publish(`installation/${deviceName}/VCC`, {
                typ: "command",
                command: "dischargeBattery",
                options: {
                    durationInMin: parseInt(this.state.duration),
                    power: parseInt(this.state.power),
                    phase: 1
                }
            }
        );
        this.setState({isOpen: false})
    }

    render() {
        let {deviceName, deviceId} = this.props

        return (
            <div>
                <div className="bg-white px-4 py-4  border-2">
                    <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                        <div className="ml-4 mt-4">
                            <div className="flex items-center ">
                                <div className="flex-shrink-0 ml-4">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{this.props.titel}</h3>
                                </div>
                                <div className="ml-4">
                                    <div
                                        className="inline-flex items-center px-2.5 py-0.5 rounded-md text-lg font-medium bg-blue-100 text-blue-800">
                                        <div
                                            className={"flex-shrink font-mono "}>
                                    <span>
                        <DisplayValue
                            value={`{${deviceName}.VCC.1.BatteryStateOfCharge_System}`}/>
                                    </span> %
                                        </div>
                                    </div>
                                    <div
                                        className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-md text-lg font-medium bg-green-100 text-green-800">
                                        <div
                                            className={"flex-shrink font-mono "}>
                                    <span>
                                        <DisplayValue
                                            value={`{${deviceName}.CGET340.${deviceId}.kWL1}+{${deviceName}.CGET340.${deviceId}.kWL2}+{${deviceName}.CGET340.${deviceId}.kWL3}`}/>
                                    </span> W
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" flex-shrink-0 flex">
                            {this.state.isOpen ? (<div className={"ml-4 mt-4 flex space-x-3 items-center"}>
                                <p> Power:</p>
                                <TextInput
                                    type={"number"}
                                    style={{width: 90}}
                                    value={this.state.power}
                                    onChange={event => {
                                        this.setState({power: event})
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            this.startAction()
                                        }
                                    }}
                                    placeholder="0"
                                />
                                <p> Duration:</p>
                                <TextInput
                                    type={"number"}
                                    style={{width: 90}}
                                    value={this.state.duration}
                                    onChange={event => {
                                        this.setState({duration: event})
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            this.startAction()
                                        }
                                    }}
                                    placeholder="0"
                                />
                                {(this.state.commandType === "charge") ? (<div className={"space-x-2"}>
                                    <button type="button"
                                            onClick={async () => {
                                                console.log(parseInt(this.state.power));
                                                await PubSub.publish(`installation/${deviceName}/VCC`, {
                                                    typ: "command",
                                                    command: "chargeBattery",
                                                    options: {
                                                        durationInMin: parseInt(this.state.duration),
                                                        power: parseInt(this.state.power)
                                                    }
                                                });

                                                this.setState({isOpen: false})
                                            }}
                                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <FiBatteryCharging className="-ml-1 mr-2 h-5 w-5 text-gray-400"/>
                                        <span>Start Charge </span>
                                    </button>
                                    <button type="button"
                                            onClick={() => {
                                                this.setState({isOpen: false})
                                            }}
                                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <IoMdClose className="  h-5 w-5 text-gray-400"/>
                                    </button>
                                </div>) : (<div className={"space-x-2"}>
                                    <button type="button"
                                            onClick={async () => {
                                                this.startAction()
                                            }}
                                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <FiBatteryCharging className="-ml-1 mr-2 h-5 w-5 text-gray-400"/>
                                        <span>Start Discharge</span>
                                    </button>

                                    <button type="button"
                                            onClick={() => {
                                                this.setState({isOpen: false})
                                            }}
                                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <IoMdClose className="  h-5 w-5 text-gray-400"/>
                                    </button>


                                </div>)}


                            </div>) : (<div className={"ml-4 mt-4"}>

                                <button type="button"
                                        onClick={() => this.setState({isOpen: true, commandType: "charge"})}
                                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <FiBatteryCharging className="-ml-1 mr-2 h-5 w-5 text-gray-400"/>
                                    <span> Charge </span>
                                </button>
                                <button type="button"
                                        onClick={() => this.setState({isOpen: true, commandType: "discharge"})}
                                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <BsPaintBucket className="-ml-1 mr-2 h-5 w-5 text-gray-400"/>
                                    <span> Discharge </span>
                                </button>
                                <button type="button"
                                        onClick={async () => {
                                            await PubSub.publish(`installation/${deviceName}/VCC`, {
                                                typ: "command",
                                                command: "batteryStop",
                                            });


                                        }}
                                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <TbHandStop className="-ml-1 mr-2 h-5 w-5 text-gray-400"/>
                                    <span> Stop </span>
                                </button>


                            </div>)}


                        </div>
                    </div>


                </div>


            </div>
        );
    }
}

StorageController.propTypes = {}

export default StorageController;