import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Database} from "../APIClient";
import {IntervalManager} from "../IntervalManager";
import Boost from 'highcharts/modules/boost';
import {evaluate} from "mathjs";
import {IotCore} from "../iotCore";

class Graph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOptions: {
                chart: {
                    displayErrors: true,
                    zoomType: 'x',
                    style: {
                        fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
                    },
                    events: {
                        load() {
                            this.showLoading('<div style=" position: relative;   width: 100%;height: 100%;"><img alt={"loading"} id="spinner" style=" left: 50%; position: absolute; " src="https://portal.ereneo.de/images/loading.gif"/></div>')
                        },
                    }
                },
                credits: {enabled: false},
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        overflow: 'justify'
                    },
                    events: {
                        setExtremes: (e) => {
                            if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
                                //reset zoom click
                                console.log('reset zoom click');
                                console.log(e);
                                if (this.state.loadedHours.length !== 0) {


                                }
                            } else {
                                //zoom-in
                            }
                        },
                        afterSetExtremes: (event) => {
                            let chart = this.chartComponent.current.chart
                            if (chart?.series?.[0] !== undefined && event.trigger === "zoom" && event.min !== undefined) {
                                let interval = IntervalManager.getInterval();

                                if (["1m", "1j", "1w"].includes(interval)) {
                                    let startDate = new Date(event.min)
                                    let endDate = new Date(event.max)


                                    if ((endDate - startDate) <= 36000000 * 3) {
                                        // kleiner als 3 h
                                        // check if this chunk was already loaded 
                                        let isThisAlreadyLodes = this.state.loadedHours.map(item => (item.endDate > startDate && item.startDate < startDate)).reduce(function (a, b) {
                                            return a || b;
                                        }, false) && this.state.loadedHours.map(item => (item.endDate > endDate && item.startDate < endDate)).reduce(function (a, b) {
                                            return a || b;
                                        }, false)

                                        if (!isThisAlreadyLodes) {
                                            this.props.graphAxis.forEach(((axis, index) => {
                                                const columnName = this.state.chartOptions.series[index].column;

                                                let match = columnName.matchAll(/{(?<clientId>[^.]*).(?<component>[^.]*).(?<deviceId>[^.]*).(?<columnName>[^.]*)}/gm)
                                                let element = match.next()
                                                let matches = []

                                                function f(element) {
                                                    let output = []
                                                    while (!element.done) {
                                                        matches.push(element)
                                                        output.push(Database.getGraphWithRange(element.value.groups.clientId + "/" + element.value.groups.component + "/" + element.value.groups.deviceId, element.value.groups.columnName, startDate, endDate))
                                                        element = match.next()
                                                    }
                                                    return output
                                                }

                                                Promise.all(f(element)).then((data) => {
                                                    let outputData = []
                                                    if (data.length !== 0) {

                                                        data[0].forEach((dataRow) => {
                                                            let newValue = columnName
                                                            let value = 0
                                                            if (dataRow[1] !== null) {

                                                                matches.forEach((match) => {
                                                                    newValue = newValue.replaceAll(match.value[0], dataRow[1].toString())
                                                                })

                                                                try {
                                                                    value = evaluate(newValue);

                                                                } catch (e) {
                                                                    console.log('The value was not compute');
                                                                    console.log(columnName);
                                                                    console.log(e);
                                                                }
                                                                outputData.push([dataRow[0], value])
                                                            }
                                                        })
                                                    }


                                                    this.setState((state) => {
                                                        let chart = state.chartOptions.series;
                                                        chart[index].data = [...chart[index].data, ...outputData].sort(function (x, y) {
                                                            return x[0] - y[0];
                                                        });
                                                        return ({
                                                            chartOptions: {
                                                                ...state.chartOptions,
                                                                series: chart
                                                            },
                                                            loadedHours: [...state.loadedHours, {endDate, startDate}]
                                                        })
                                                    })
                                                })

                                            }))
                                        }
                                    }
                                }

                            }
                        }
                    },
                    minRange: 30 * 1000 // 30 s

                },
                tooltip: {
                    crosshairs: {
                        dashStyle: 'solid'
                    },
                },
                yAxis: [{ //Axis 0 Leistung 1
                    labels: {
                        format: '{value} W',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    title: {
                        text: 'Leistung',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    lineWidth: 0.5,
                    opposite: true,
                    visible: props.graphAxis.map(x => x.yAxis === 0).includes(true)

                }, { //Axis 1 Leistung 2
                    labels: {
                        format: '{value} W',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    title: {
                        text: 'Leistung',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    lineWidth: 0.5,
                    opposite: false,
                    visible: props.graphAxis.map(x => x.yAxis === 1).includes(true)


                }, { //Axis 2 Energie 1
                    gridLineWidth: 0,
                    title: {
                        text: 'Energie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} kWh',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    maxRange: 1000,

                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 2).includes(true),
                    opposite: true
                }, { //Axis 3 Energie 2
                    gridLineWidth: 0,
                    title: {
                        text: 'Energie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} kWh',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 3).includes(true),
                    opposite: false
                }, { //Axis 4 Autarkie
                    gridLineWidth: 0,
                    title: {
                        text: 'Autarkie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} %',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 4).includes(true),
                    opposite: false
                }, { //Axis 5 Eigenverbrauch
                    gridLineWidth: 0,
                    title: {
                        text: 'Eigenverbrauch',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} %',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 5).includes(true),

                    opposite: false
                }, { //Axis 6 Frequenz
                    gridLineWidth: 0,
                    title: {
                        text: 'Frequenz',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} Hz',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 6).includes(true),

                    opposite: false
                }, { //Axis 7 Spannung
                    gridLineWidth: 0,
                    title: {
                        text: 'Spannung',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} V',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 7).includes(true),
                    opposite: false
                }, { //Axis 8 Strom
                    gridLineWidth: 0,
                    title: {
                        text: 'Strom',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} A',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 8).includes(true),

                    opposite: false
                }, { //Axis 9 Ladezustand
                    gridLineWidth: 0,
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Ladezustand',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} %',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 9).includes(true),

                    opposite: false
                }, { //Axis 10 VEBus
                    gridLineWidth: 0,

                    title: {
                        text: 'VE State',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    categories: ['Off', 'Low Power', 'Fault', 'Bulk', 'Absorption', 'Float', 'Storage', 'Equalize', 'Passthru', 'Inverting', 'Power assist', 'Power supply', 'Bulk protect'],
                    min: 0,
                    max: 12,
                    lineWidth: 0.5,
                    visible: props.graphAxis.map(x => x.yAxis === 10).includes(true),

                    opposite: false
                }


                ],
                legend: {
                    enabled: true
                },
                colors: ['#3498db', '#e67e22', '#2ecc71', '#34495e', '#aaeeee', '#9b59b6',
                    '#e74c3c', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
                plotOptions: {
                    columnrange: {
                        dataLabels: {
                            enabled: true,
                            format: '{y}°C'
                        }
                    },
                    series: {
                        states: {
                            inactive: {
                                opacity: 0.99
                            }
                        }
                    },
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 1,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                                [1, Highcharts.getOptions().colors[2]],
                            ]
                        },
                        marker: {
                            radius: 1
                        },
                        lineWidth: 0.5,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: 0
                    }
                },

                series: props.graphAxis,

            },
            loadedHours: []
        }
        this.chartComponent = React.createRef();
        Boost(Highcharts);
        Highcharts.setOptions(
            {
                global: {
                    useUTC: false
                }
            });
    }


    updateSeries = (data, axes) => {
        this.setState((state) => {
            let chart = state.chartOptions.series;
            chart[axes].data = data;
            return ({
                chartOptions: {
                    ...state.chartOptions,
                    series: chart
                },
                loadedHours: []
            })
        })
    };


    componentDidMount() {
        this.fetchData()
        this.intervallChange = IntervalManager.onIntervalChange(() => {
            this.fetchData()
        })
        setTimeout(() => {
            this.loadLiveData()

        }, 1000)
    }

    componentWillUnmount() {
        this.intervallChange();
    }

    loadLiveData() {

        this.unsubscribe = IotCore.onValueChange((data) => {
            let interval = IntervalManager.getInterval();
            if (interval !== "timespan" && interval !== "yesterday") {

                this.props.graphAxis.forEach(((axis, index) => {

                    const columnName = this.state.chartOptions.series[index].column;

                    let match = columnName.matchAll(/{(?<clientId>[^.]*).(?<component>[^.]*).(?<deviceId>[^.]*).(?<columnName>[^.]*)}/gm)
                    let element = match.next()
                    let matches = []

                    while (!element.done) {
                        matches.push(element)
                        element = match.next()
                    }
                    let timestamp = 0
                    let newValue = columnName
                    let value = 0

                    matches.forEach((match, mIndex) => {
                        timestamp = data[match.value.groups.clientId][match.value.groups.component][match.value.groups.deviceId].timestamp
                        newValue = newValue.replace(match.value[0], "(" + (data[match.value.groups.clientId][match.value.groups.component][match.value.groups.deviceId][match.value.groups.columnName] || 0).toString() + ")")
                    })

                    try {
                        value = evaluate(newValue);

                    } catch (e) {
                        console.log('The value was not compute');
                        console.log(columnName);
                        console.log(e);
                    }

                    if (this?.chart?.series?.[index] !== undefined) {

                        this.chart.series[index].addPoint([parseInt(timestamp) * 1000, value], true, (this.props.graphAxis.length + 1 === index), false)
                    }


                }))
            }

        })


    }

    median(values) {
        if (values.length === 0) throw new Error("No inputs");

        values.sort(function (a, b) {
            return a - b;
        });

        var half = Math.floor(values.length / 2);

        if (values.length % 2)
            return values[half];

        return (values[half - 1] + values[half]) / 2.0;
    }

    hampelFilter(data, half_window, threshold) {
        if (typeof threshold === 'undefined') {
            threshold = 3;
        }
        var n = data.length;
        var data_copy = data;
        var ind = [];
        var L = 1.4826;
        for (var i = half_window + 1; i < n - half_window; i++) {
            var med = this.median(data.slice(i - half_window, i + half_window).map(item => (item[1])));
            var MAD = L * this.median(data.slice(i - half_window, i + half_window).map(item => (item[1])).map(function (e) {
                return Math.abs(e - med)
            }));
            if (Math.abs(data[i][1] - med) / MAD > threshold) {
                data_copy[i] = med;
                ind = ind.concat(i);
            }
        }
        return {
            data2: data_copy,
            outliers: ind
        };
    }


    fetchData() {
        let currentDates = IntervalManager.getDates()
        if (this.chart !== undefined) {
            try {
                this.chart.showLoading('<div style=" position: relative;   width: 100%;height: 100%;"><img alt={"loading"} id="spinner" style=" left: 50%; position: absolute; " src="https://portal.ereneo.de/images/loading.gif"/></div>')
            } catch (e) {

            }
        }
        let interval = IntervalManager.getInterval();
        console.log(interval);
        this.props.graphAxis.forEach(((axis, index) => {

            const columnName = this.state.chartOptions.series[index].column;

            let match = columnName.matchAll(/{(?<clientId>[^.]*).(?<component>[^.]*).(?<deviceId>[^.]*).(?<columnName>[^.]*)}/gm)
            let element = match.next()
            let matches = []

            function f(element) {
                let output = []
                while (!element.done) {
                    matches.push(element)
                    if (interval === "timespan") {
                        output.push(Database.getGraphWithRange(element.value.groups.clientId + "/" + element.value.groups.component + "/" + element.value.groups.deviceId, element.value.groups.columnName, currentDates.startDate, currentDates.endDate))

                    } else {

                        output.push(Database.getGraph(element.value.groups.clientId + "/" + element.value.groups.component + "/" + element.value.groups.deviceId, element.value.groups.columnName, interval))
                    }
                    element = match.next()
                }
                return output
            }

            Promise.all(f(element)).then((data) => {
                let outputData = []
                if (data.length !== 0) {
                    console.log(data);
                    data[0].forEach((dataRow, index) => {
                        let newValue = columnName
                        let value = 0
                        if (dataRow[1] !== null) {

                            if (matches !== undefined) {


                                matches.forEach((match, mIndex) => {
                                    newValue = newValue.replaceAll(match.value[0], "(" + data[mIndex]?.[index]?.[1]?.toString() + ")")
                                })

                                try {
                                    value = evaluate(newValue);

                                } catch (e) {
                                    console.log('The value was not compute');
                                    console.log(columnName);
                                    console.log(e);
                                }

                                outputData.push([dataRow[0], value])
                            }
                        }

                    })
                }
                console.log(outputData);
                // let {data2, outliers} = (this.hampelFilter(outputData, 5, 3));
                // outputData = data2
                this.updateSeries(outputData, index);
                if (this.chart !== undefined) {

                    this.chart.hideLoading();
                    this.chart.zoomOut()

                }
            })

        }))

    }

    render() {
        return (
            <div>
                <HighchartsReact
                    style={{display: "inline-block"}}
                    {...this.props}
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                    ref={this.chartComponent}
                    callback={(chart) => {
                        this.chart = chart

                    }}
                />
            </div>
        );
    }
}

Graph.propTypes = {
    graphAxis: PropTypes.array
};

export default Graph;