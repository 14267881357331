import React, {Component} from 'react';
import {Data} from "../APIClient";
import InfiniteScroll from "react-infinite-scroll-component";
import {Transition} from "react-transition-group";
import availableDevices from "../Devices";


const transitonStyle = {
    entering: " opacity-0 -translate-y-10",
    entered: " opacity-100 translate-y-0",
    exiting: " translate-y-0",
    exited: " translate-y-0",
};

class RowData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consoleArray: [],
            currentSelectedDevice: availableDevices[0],
            availableDevices: availableDevices,
            isDrawerOpen: false
        }
    }


    fetchData() {
        Data.get(this.state.currentSelectedDevice.deviceId, 0).then((data) => {
            if (data !== undefined) {

                this.setState({consoleArray: data})
                // scroll to the end of the timeline

            }
        })

    }

    componentDidMount() {
        this.fetchData()

    }

    loadNextChunk() {
        Data.get(this.state.currentSelectedDevice.deviceId, this.state.consoleArray.length).then((data) => {
            this.setState((state) => ({
                consoleArray: ([...data, ...state.consoleArray].sort(function (x, y) {
                    return y.timestamp - x.timestamp;
                }))
            }))
        })
    }

    render() {
        return (
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Daten View </h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="py-4">

                        <div className="bg-white shadow mb-3 w-full">
                            <div className="px-4 py-5 sm:px-6 hover:bg-slate-50 cursor-pointer" onClick={() => {
                                this.setState((state) => ({isDrawerOpen: !state.isDrawerOpen}))
                            }}>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {this.state.currentSelectedDevice.deviceName}
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    {this.state.currentSelectedDevice.deviceDescription}
                                </p>
                            </div>
                            <Transition in={this.state.isDrawerOpen} timeout={100} unmountOnExit={true}>
                                {state => (<div
                                    className={("p-3 transition-all ease-in-out duration-100 " + transitonStyle[state])}>


                                    <fieldset>

                                        <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                            {this.state.availableDevices.map(device => {

                                                let isCurrent = device.deviceId === this.state.currentSelectedDevice.deviceId

                                                return (<label
                                                    className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none hover:bg-slate-50"
                                                    onClick={() => {
                                                        this.setState({
                                                            currentSelectedDevice: device,
                                                            isDrawerOpen: false
                                                        }, () => {
                                                            this.fetchData()

                                                        })

                                                    }}>
                                                    <input type="radio" name="project-type" defaultValue="Newsletter"
                                                           className="sr-only" aria-labelledby="project-type-0-label"
                                                           aria-describedby="project-type-0-description-0 project-type-0-description-1"/>
                                                    <div className="flex-1 flex">
                                                        <div className="flex flex-col">
          <span id="project-type-0-label" className="block text-sm font-medium text-gray-900">
            {device.deviceName}
          </span>
                                                            <span id="project-type-0-description-0"
                                                                  className="mt-1 flex items-center text-sm text-gray-500">
            {device.deviceDescription}
          </span>
                                                            <span id="project-type-0-description-1"
                                                                  className="mt-6 text-sm font-medium text-gray-900">
            {device.deviceLocation}
          </span>
                                                        </div>
                                                    </div>
                                                    <svg
                                                        className={("h-5 w-5 text-indigo-600 " + (isCurrent ? ("") : ("invisible")))}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>
                                                    <div
                                                        className={"absolute -inset-px rounded-lg border-2 pointer-events-none " + (isCurrent ? ("border") : ("border-2"))}
                                                        aria-hidden="true"/>
                                                </label>)
                                            })}

                                        </div>
                                    </fieldset>
                                </div>)}
                            </Transition>

                        </div>


                        <div className="border-4  border-gray-200 rounded-lg  flex flex-col">
                            <div className="flex flex-col">
                                <InfiniteScroll
                                    dataLength={this.state.consoleArray.length} //This is important field to render the next data
                                    next={() => {
                                        this.loadNextChunk()
                                    }}
                                    scrollableTarget="console"
                                    hasMore={(this.state.consoleArray.length < 500)}
                                    loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}


                                >
                                    <div
                                        className="shadow  border-b border-gray-200 sm:rounded-lg  overflow-scroll h-96 max-w-full customScrollbar"
                                        id="console">
                                        <table
                                            className="min-w-full divide-y divide-gray-200 ">
                                            <thead className="bg-gray-50 sticky top-0">
                                            {(this.state.consoleArray.length !== 0) && (<tr>
                                                {Object.keys(this.state.consoleArray[0]).map(columnName => (
                                                    <th scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {columnName}
                                                    </th>))}
                                            </tr>)}

                                            </thead>


                                            <tbody>
                                            {this.state.consoleArray.map((row, index) => {

                                                let date = new Date(row.timestamp * 1000);

                                                let month = date.getMonth() + 1;
                                                let day = date.getDate();
                                                let hour = date.getHours();
                                                let min = date.getMinutes();
                                                let sec = date.getSeconds();
                                                let year = date.getFullYear()

                                                return (
                                                    <tr key={hour + ":" + min + ":" + sec}
                                                        className={((index % 2 === 0) ? ("bg-white") : ("bg-gray-50"))}>

                                                        {Object.keys(row).map(columnName => {
                                                            if (columnName === "timestamp") {
                                                                return (
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                        {day + "." + month + "." + year + "  " + hour + ":" + min + ":" + sec}
                                                                    </td>)
                                                            }
                                                            return (
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                    {row[columnName]}
                                                                </td>
                                                            )
                                                        })}


                                                    </tr>)

                                            })}
                                            </tbody>


                                        </table>

                                    </div>
                                </InfiniteScroll>
                                {(!(this.state.consoleArray.length < 500) && (
                                    <div className={"w-full bg-blue-400 text-white font-bold text-center"}> In this view
                                        only the last 500 rows can be viewed </div>))}
                            </div>


                        </div>


                    </div>
                </div>

            </div>
        );
    }
}

RowData.propTypes = {};

export default RowData;