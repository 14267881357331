import {createStore} from "redux";


const intervalStore = createStore((state = {}, action) => {

    switch (action.type) {
        case "setInterval":
            state.interval = action?.data?.interval || "24h"
            state.lable = action?.data?.lable || ""
            break;
        case "setStartDate":
            state.startDate = action?.data?.date
            break;
        case "setEndDate":
            state.endDate = action?.data?.date
            break;

    }


    return state

})

const IntervalManager = function () {
    function setInterval(interval,lable) {
        intervalStore.dispatch({
            type: "setInterval",
            data: {interval,lable},
        })
    }

    function setStartDate(date) {
        intervalStore.dispatch({
            type: "setStartDate",
            data: {date},
        })
    }

    function setEndDate(date) {
        intervalStore.dispatch({
            type: "setEndDate",
            data: {date},
        })
    }


    function getDates() {
        let state = intervalStore.getState()
        return {startDate: state.startDate, endDate: state.endDate}
    }

    function getInterval() {
        let state = intervalStore.getState()
        return state?.interval || "24h"
    }

    function getLable() {
        let state = intervalStore.getState()
        return state?.lable || "Letzten 24 Stunde"
    }

    function onIntervalChange(callback) {
        return intervalStore.subscribe(() => {
            callback()
        })
    }

    return {
        setInterval: setInterval,
        getInterval: getInterval,
        onIntervalChange: onIntervalChange,
        setStartDate: setStartDate,
        setEndDate: setEndDate,
        getDates: getDates,
        getLable:getLable
    }

}()
export {
    IntervalManager
};