import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ControlGraphic from "../pages/ControlGraphic";
import InteractiveGraphic from "../components/InteractiveGraphic";
import ArlmuehleBackground from "../circuitSymbols/ArlmuehleBackground";
import ArlmuehleGraphic from "../InteractiveGraphics/ArlmuehleGraphic";
import KundencenterBackground from "../circuitSymbols/KundencenterBackground";
import KundencenterGraphic from "../InteractiveGraphics/KundencenterGraphic";
import SteuerbildBackground from "../circuitSymbols/SteuerbildBackground";
import SteuerbildGraphic from "../InteractiveGraphics/SteuerbildGraphic";
import BetriebszentraleBackground from "../circuitSymbols/BetriebszentraleBackground";
import BetriebszentraleGraphic from "../InteractiveGraphics/BetriebszentraleGraphic";
import StorageController from "../components/StorageController";

class BetriebszentralePage extends Component {
    render() {
        return (
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Betriebszentrale</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-4">

                    <InteractiveGraphic background={<BetriebszentraleBackground width={"1100px"} height={"1100px"}/>}
                                        graphicElements={BetriebszentraleGraphic}/>

                    <StorageController titel={"Speicher Betriebszentrale"} deviceName={"ereneo-monitoring-node-betriebszentrale"}  deviceId={"25"} />
                </div>
            </div>
        );
    }
}

BetriebszentralePage.propTypes = {};

export default BetriebszentralePage;