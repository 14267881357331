import React, {Component} from 'react';
import PropTypes from "prop-types";

class SteuerbildBackground extends Component {
    render() {
        return (<>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                     width={this.props.width} height={this.props.height} viewBox="-0.5 -0.5 562 162"
                     content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-06-06T09:57:30.422Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;7FSyb97btd8OTb3slY1A&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;h5EHXLx7pRkKu_khZ0wB&quot; name=&quot;Page-1&quot;>5VVdT4MwFP01PJpA6dj2qHPTB00Wl0zfTLNeoUmhWMoG/nqLLV8ic4vGF5/oPZz7dXoCjr+IixtJ0uheUOAOcmnh+NcOQh5GE/2okNIg0zk2QCgZtaQW2LA3sKBr0ZxRyHpEJQRXLO2DO5EksFM9jEgpDn3ai+D9rikJYQBsdoQP0UdGVWTQGZq2+C2wMKo7e8HcvIlJTbabZBGh4tCB/KXjL6QQypziYgG8Eq/WxeStRt42g0lI1CkJqyeyLdV6i+P1g3v3jItXgi9slT3huV3YDqvKWgFI6GUlpI4SkWjwKlIx15Gnj1LkCQVqI5MLdCBpO6PXbK4tAyIGJUtNObTaTqxeUUfWGpPAiWL7fnlirzhsyjUd1oLpxsi1dpzWd2HNiGZuv0QmcrkDm9XV8lMhhL8ppIgMQQ0KaSFJ2aGlFSEbHziYfd1nbK7A7fMxPs73g/P46Pg8+mA2rKPOHbfQhy/P8Cj6Xx4Nfsuj7h95dKTPqZ77oYd02H5DDb39E/nLdw==</diagram></mxfile>"
                     style={{backgroundColor: 'rgb(255, 255, 255)'}}>
                    <defs/>
                    <g>
                        <path
                            d="M 520 0 L 490 0 Q 480 0 475.53 8.94 L 404.47 151.06 Q 400 160 390 160 L 170 160 Q 160 160 155.53 151.06 L 84.47 8.94 Q 80 0 70 0 L 40 0"
                            fill="none" stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 560 0 L 410 0 Q 400 0 390 0 L 170 0 Q 160 0 150 0 L 0 0" fill="none"
                              stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                    </g>
                </svg>


            </>

        );
    }
}

SteuerbildBackground.propTypes = {
    width: PropTypes.string, height: PropTypes.string,
};

export default SteuerbildBackground;