import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {IotCore} from "../iotCore";
import {Database} from "../APIClient";

const {evaluate} = require('mathjs')

class DisplayValue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0
        }
    }

    componentDidMount() {


        // {AGG.(?<component>[^.]*).(?<deviceId>[^.]*).(?<valueName>[^.]*)(.|)(?<interval>[^.]*|)}

        const value = this.props.value

        this.unsubscribe = IotCore.onValueChange((data) => {
            this.setState({count: this.state.count + 1})
        })
        // load values
        let match = value.matchAll(/{(?<clientId>[^.]*).(?<component>[^.]*).(?<deviceId>[^.]*).(?<valueName>[^.]*)}/gm);
        let element = match.next()
        while (!element.done) {
            IotCore.loadFromDatabase(element.value.groups.clientId, element.value.groups.component, element.value.groups.deviceId, element.value.groups.valueName)
            element = match.next()
        }

        // load aggregation
        let matchAggregation = value.matchAll(/{AGG.(?<clientId>[^.]*).(?<component>[^.]*).(?<deviceId>[^.]*).(?<valueName>[^.]*)(.|)(?<interval>[^.]*|)}/gi);
        let aggregationElement = matchAggregation.next()

        while (!aggregationElement.done) {
            let clientId = aggregationElement.value.groups.clientId
            let component = aggregationElement.value.groups.component
            let deviceId = aggregationElement.value.groups.deviceId
            let valueName =  aggregationElement.value.groups.valueName
            let interval =  aggregationElement.value.groups.interval ||"today"

            IotCore.loadAggregateFromDatabase(clientId,component, deviceId, valueName,interval)
            aggregationElement = matchAggregation.next()
        }
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {

        // replace value
        let match = this.props.value.matchAll(/{(?<clientId>[^.]*).(?<component>[^.]*).(?<deviceId>[^.]*).(?<valueName>[^.]*)}/gm)

        let outputString = this.props.value
        let element = match.next()
        while (!element.done) {
            let value = IotCore.getValue(element.value.groups.clientId, element.value.groups.component, element.value.groups.deviceId, element.value.groups.valueName) || 0
            outputString = outputString.replace(element.value[0], value.toString())
            element = match.next()
        }


        // replace aggregation
        let matchAggregation = this.props.value.matchAll(/{AGG.(?<clientId>[^.]*).(?<component>[^.]*).(?<deviceId>[^.]*).(?<valueName>[^.]*)(.|)(?<interval>[^.]*|)}/gi);
        let aggregationElement = matchAggregation.next()

        while (!aggregationElement.done) {
            let clientId = aggregationElement.value.groups.clientId
            let component = aggregationElement.value.groups.component
            let deviceId = aggregationElement.value.groups.deviceId
            let valueName =  aggregationElement.value.groups.valueName
            let interval =  aggregationElement.value.groups.interval ||"today"
            let value = IotCore.getValue(clientId, component, deviceId, (valueName +"__"+interval)) || 0
            outputString = outputString.replace(aggregationElement.value[0], value.toString())
            aggregationElement = matchAggregation.next()
        }

        let value;
        try {
            value = evaluate(outputString);

        } catch (e) {
            // console.log('The value was not compute');
            // console.log(outputString);
            // console.log(e);
        }

        value = Math.round(value * 100) / 100
        value = value.toFixed(0)

        return value || "--";
    }
}

DisplayValue.propTypes = {};

export default DisplayValue;