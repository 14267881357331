import React, {Component} from 'react';
import PropTypes from "prop-types";

class KundencenterBackground extends Component {
    render() {
        return (<>


                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                     width={this.props.width} height={this.props.height} viewBox="-0.5 -0.5 922 362"
                     content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-06-06T09:35:07.836Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;6FGTHSVQzhBh3aUG8OC2&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;h5EHXLx7pRkKu_khZ0wB&quot; name=&quot;Page-1&quot;>5VZdb4IwFP01PJpAK4iPGzpNpnGJ+4iPDa1ABpSVosKvX12LUNymM+7JJ3oPt/eennv4MKCX7CYMZeGcYhIbwMQ7A44MAIA7hOKyR0qJWJYNJBKwCCusAZZRRRRoKrSIMMm1RE5pzKNMB32apsTnGoYYo1s9bU1jvWuGAnIELH0UH6NvEeahRF0waPApiYKw7mw5Q3knQXWyOkkeIky3LQiODegxSrlcJTuPxHv1al3kvocf7h6IMZLyczYsErzyvGoajGfbx5f31wHOq54tq2xQXKgDK7K8rBUgKb7bCymilKYCvA95EovIEktGixQTrKJjSoolwZrKiuCE0IRwVoqEbaOtrfQKW7LWGCMx4tFGnw1SIw4O5Q4dnmgkmABT+dEaquEoNzquqZfIacF8ona1tewUsjuFIOgU4ogFhB8VEkKispWW7RPy8wnbjvkrr24+1D0hFpJBHbVm0EBfvvmDhyxwUybqwxOzP9dEUA2zLtTv/4+JuoTrPtcyxXJkPg9C8DEJq5UzWi/M+WjWgzflCfEM6LO8+M3imicKXckUw+/bXOgJETYfMpne/A/A8Sc=</diagram></mxfile>"
                     style={{backgroundColor: 'rgb(255, 255, 255)'}}>
                    <defs/>
                    <g>
                        <path d="M 319 360 L 319 250 Q 319 240 319 230 L 319 10 Q 319 0 329 0 L 719 0" fill="none"
                              stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 559 0 L 559 110 Q 559 120 549 120 L 480 120" fill="none" stroke="rgb(0, 0, 0)"
                              strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 0 360 L 200 360 Q 210 360 220 360 L 920 360" fill="none" stroke="rgb(0, 0, 0)"
                              strokeMiterlimit={10} pointerEvents="stroke"/>
                    </g>
                </svg>


            </>

        );
    }
}

KundencenterBackground.propTypes = {
    width: PropTypes.string, height: PropTypes.string,
};

export default KundencenterBackground;