import React, {Component} from 'react';
import PropTypes from "prop-types";

class ArlmuehleBackground extends Component {
    render() {
        return (
            <svg width={this.props.width} height={this.props.height} xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                 viewBox="-0.5 -0.5 769 492"
                 content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-02-04T13:23:32.348Z&quot; agent=&quot;5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/13.3.1 Chrome/83.0.4103.119 Electron/9.0.5 Safari/537.36&quot; etag=&quot;_C3-jdKukgTjtuUPuaWA&quot; version=&quot;13.3.1&quot; type=&quot;device&quot;><diagram id=&quot;GrKlIlBMFe1Yrf0LDZKQ&quot; name=&quot;Page-1&quot;>7Zddb5swFIZ/DZeVMISvyy3rtos2qlRtvfbiM7AEODImH/31c2o72CZ0W5JOq9qrcF5ejl/7PAoiiOfN9gvHq+qWEaiDKCTbIP4URBFKi0z+7JWdUopMCyWnRJsG4Z4+ghZDrfaUQOcYBWO1oCtXXLK2haVwNMw527i2n6x2V13hEkbC/RLXY/WBElEpNY+yQf8KtKzMynLH6k6DjVnvpKswYRtLiq+DeM4ZE+qq2c6h3h+eORf13OeJu4dgHFrxJw+UC9rfLMr0rvm+rh/6+QJffbuKVJc1rnu9YR1W7MwJQEs+7A9SVi1rpfixEk0tKyQvG7bGP56coaw4dPTRrpnAwqolHGDXQKhd6hlaigoDZDSjYdPocJSSQWANCL6Tls0wrEQPoLLmZDQONRZ07bbHmpny0O6wwh2jcuEoNHyjWDfSeGd56PboWM+XoB+zp+N3CmdupzjzOgnMSxCjTnI2eGfZVntDNx05LY4nngrm+00u7ZcXKoGprCkM0hOKf4Hl7B3Lc7CcIXdmeXoqlcj8XetOCXoZKpP8eOKpYL7f5HpRKpOLUYk8KpFHJfKoRC6VaEQlegVUjmB6x/IiWKZnYvm/c5N7h5rGJ2KTJ8enc2lqiux44Klcvj/JnveP9vEvXsnZW6OsOJGyYvYbXC9Fmb9OcRYFshy+RZR9+KKLr38B</diagram></mxfile>"
                 style={{backgroundColor: 'rgb(255, 255, 255)'}}>
                <defs/>
                <g>
                    <path d="M 720 410 L 290 410 Q 280 410 280 400 L 280 10 Q 280 0 290 0 L 630 0" fill="none"
                          stroke="#000000" strokeMiterlimit={10} pointerEvents="stroke"/>
                    <path d="M 0 490 L 160 490 Q 170 490 170 480 L 170 150 Q 170 140 180 140 L 767 140" fill="none"
                          stroke="#000000" strokeMiterlimit={10} pointerEvents="stroke"/>
                    <path d="M 767 490 L 180 490 Q 170 490 170 480 L 170 150 Q 170 140 180 140 L 767 140" fill="none"
                          stroke="#000000" strokeMiterlimit={10} pointerEvents="stroke"/>
                    <path
                        d="M 470 260 L 550 260 Q 560 260 560 250 L 560 210 Q 560 200 550 200 L 450 200 Q 440 200 440 190 L 440 140"
                        fill="none" stroke="#000000" strokeMiterlimit={10} pointerEvents="stroke"/>
                    <path d="M 470 320 L 520 320 Q 530 320 530 310 L 530 260" fill="none" stroke="#000000"
                          strokeMiterlimit={10} pointerEvents="stroke"/>
                </g>
            </svg>

        );
    }
}

ArlmuehleBackground.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};

export default ArlmuehleBackground;