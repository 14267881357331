import React, {Component} from 'react';
import {BiBuildings, BiHome, BiTerminal} from "react-icons/bi";
import {SiGnubash} from "react-icons/si";
import {FiUsers} from "react-icons/fi";
import {AiOutlineSetting} from "react-icons/ai";
import {ImPageBreak} from "react-icons/im";
import {IoFlaskOutline} from "react-icons/io5";
import {Link} from "react-router-dom";
import {TiFlowSwitch} from "react-icons/ti";
import {GiWaterMill} from "react-icons/gi";
import {TbInfoSquare} from "react-icons/tb";

class PageMenu extends Component {
    render() {

        return (
            <nav className="mt-5 px-2 space-y-1">
                {/* Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" */}
                <Link to={"/"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <BiHome className="text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>
                    Dashboard
                </Link>
                <Link to={"/controlFlow"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <TiFlowSwitch  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>
                    Control Flow
                </Link>
                <Link to={"/device/ereneo-monitoring-node-arlmuehle"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <GiWaterMill  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>
                    Arlmühle
                </Link>
                <Link to={"/device/ereneo-monitoring-node-kundencenter"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <TbInfoSquare  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>
                    Kundencenter
                </Link>
                <Link to={"/device/ereneo-monitoring-node-betriebszentrale"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <BiBuildings  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>
                    Betriebszentrale
                </Link>

                <Link to={"/console"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <BiTerminal  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>
                    Console
                </Link>
                <Link to={"/data"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <IoFlaskOutline  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>
                   Raw Data
                </Link>

                {/*<Link to={"/users"}  className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">*/}
                {/*    <FiUsers  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>*/}

                {/*    Users*/}
                {/*</Link>*/}
                <Link to={"/settings"} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                    <AiOutlineSetting  className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"/>

                    Settings
                </Link>

            </nav>
        );
    }
}

export default PageMenu;