import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {IoChevronDownOutline} from "react-icons/io5";
import Graph from "./Graph";
import {Transition} from "react-transition-group";
import DayPicker from "react-day-picker";
import 'react-day-picker/lib/style.css';
import {IntervalManager} from "../IntervalManager";
import TimeWindowSelection from "./TimeWindowSelection";


class ChartContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {

        // {
        //     name: "VL2_N",
        //         yAxis: 3,
        //     visible: true,
        //     column: "{"+this.props.deviceString+".VL2_N}",
        //     color: "#8c8088",
        //     standardInterval: "today"
        // }

        return (
            <div
                className="col-span-12 row-span-6 bg-white overflow-hidden shadow-lg border-2 border-gray-200 rounded-md divide-y divide-gray-200">
                <div className="px-2 py-3 sm:px-6">
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                        </div>
                        <div className="mt-4 flex md:mt-0 md:ml-2">
                            <TimeWindowSelection/>
                        </div>
                    </div>
                </div>
                <div className="px-4 pt-5 ">
                    <Graph interval={"24h"}
                           graphAxis={[{
                               name: "Leistung",
                               yAxis: 2,
                               visible: true,
                               column: "{"+this.props.deviceString+".kWL1}+{"+this.props.deviceString+".kWL2}+{"+this.props.deviceString+".kWL3}",
                               color: "#0ea5e9",
                               standardInterval: "today"
                           }, ]}
                           cangeCount={1}/></div>
            </div>
        );
    }
}

ChartContainer.propTypes = {};

export default ChartContainer;