import React, {Component} from 'react';
import {IoChevronForwardOutline} from "react-icons/io5";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import DisplayValue from "../components/DisplayValue";

class ValueText extends Component {
    render() {
        return (
            <div>
                <div className={"flex-shrink font-mono "}>
                    <span> <DisplayValue
                    value={this.props.value}/></span> kWh </div>
            </div>
        );
    }
}

ValueText.propTypes = {
    text: PropTypes.string
};

export default ValueText;