import React, {Component} from 'react';
import {IoChevronForwardOutline} from "react-icons/io5";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import DisplayValue from "../components/DisplayValue";

class MeasuringPoint extends Component {
    render() {
        return (
            <Link to={this.props.link || "#"}
                className={" h-16 w-40 flex flex-row items-center rounded-md p-3 border-2 border-gray-200 shadow-sm hover:shadow-md group cursor-pointer bg-white"}
            >
                <div className={"flex-grow w-4 font-semibold"}>{this.props.text}</div>
                <div className={"flex-shrink font-mono "}><DisplayValue
                    value={this.props.value}/>W </div>
                <div
                    className={"inline-flex justify-center w-8  -mr-2 transition-transform group-hover:translate-x-0.5 "}>
                    <IoChevronForwardOutline/></div>
            </Link>
        );
    }
}

MeasuringPoint.propTypes = {
    text: PropTypes.string,
    link:PropTypes.string

};

export default MeasuringPoint;