import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {IntervalManager} from "../IntervalManager";
import {IoChevronDownOutline} from "react-icons/io5";
import {Transition} from "react-transition-group";
import DayPicker from "react-day-picker";

const userInfoTransitonSyles = {
    entering: "transition ease-out duration-100 transform opacity-0 scale-95",
    entered: "transition ease-out duration-100 transform opacity-100 scale-100",
    exiting: "transition ease-in duration-75 transform opacity-100 scale-100",
    exited: "transition ease-in duration-75 transform opacity-0 scale-95 hidden",
};

class TimeWindowSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectstartData: true,
            selectendDate: false,
            lable: "",
        }
    }

    changeIntervall(interval, label) {
        IntervalManager.setInterval(interval, label)
        this.setState((state) => ({isOpen: false}))
    };

    onStartDayClick(day, selected) {
        let currentDates = IntervalManager.getDates()

        if (currentDates.endDate !== undefined) {

            let startdate = Date.parse(day) / 1000 - 46800; // -3600 for time zone an 43200 because of midnight
            let enddate = Date.parse(currentDates.endDate) / 1000 - 43200; // -3600 for time zone an 43200 because of midnight
            if (Date.parse(currentDates.endDate) === Date.parse(day)) {
                startdate = Date.parse(day) / 1000 - 46800; // -3600 for time zone an 43200 because of midnight
                enddate = Date.parse(currentDates.endDate) / 1000 - 43200; // -3600 for time zone an 43200 because of midnight


            }
            if (startdate < enddate) {
                let old = enddate;
                enddate = startdate;
                startdate = old;
            }


        }
        IntervalManager.setStartDate(selected ? undefined : day)
        this.setState((state) => ({isOpen: true}))
    }

    onEndDayClick(day, selected) {
        let currentDates = IntervalManager.getDates()

        if (currentDates.startDate !== undefined) {

            let startdate = Date.parse(day) / 1000 - 46800; // -3600 for time zone an 43200 because of midnight
            let enddate = Date.parse(currentDates.startDate) / 1000 - 43200; // -3600 for time zone an 43200 because of midnight
            if (Date.parse(currentDates.startDate) === Date.parse(day)) {
                startdate = Date.parse(day) / 1000; // -3600 for time zone an 43200 because of midnight
                enddate = Date.parse(currentDates.startDate) / 1000 - 43200; // -3600 for time zone an 43200 because of midnight
            }
            if (startdate < enddate) {
                let old = enddate;
                enddate = startdate;
                startdate = old;
            }

        }

        IntervalManager.setEndDate(selected ? undefined : day)
        IntervalManager.setInterval("timespan")
        this.setState((state) => ({isOpen: true}))
    }

    componentDidMount() {

        this.intervallChange = IntervalManager.onIntervalChange(() => {
            let lable = IntervalManager.getLable()
            console.log(lable);
            this.setState({lable: lable})
        })
    }

    componentWillUnmount() {
        this.intervallChange()
    }

    render() {
        const birthdayStyle = `.DayPicker-Day--highlighted {
                    background-color: #2ecc71;
                    color: white;
                     opacity: 0.8;
                }`;
        let currentDates = IntervalManager.getDates()

        return (
            <div>
                <button type="button" onClick={() => {
                    this.setState((state) => ({isOpen: !state.isOpen}))
                }}
                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Auswahl Zeitfenster {this.state.lable}
                    <IoChevronDownOutline className="ml-2 -mr-1 h-5 w-5"/>
                </button>

                <Transition in={this.state.isOpen} mountOnEnter={true}>
                    {state => (

                        <div
                            className={"origin-top-right absolute right-12 mt-12 w-90   bg-white   focus:outline-none border-2 shadow-md rounded p-6 z-10 grid grid-rows-1 grid-cols-2 grid-flow-row" + userInfoTransitonSyles[state]}
                            style={{width: "940px"}} role="menu" aria-orientation="vertical"
                            aria-labelledby="user-menu">

                            <div className="row-span-3">
                                <h5 className=" text-secondary" style={{

                                    width: "100%",
                                    "marginBottom": "13px"

                                }}>Zeitspanne:</h5>
                                <span className="relative z-0 pb-5 inline-flex shadow-sm rounded-md w-72">
                                              <button type="button" onClick={() => this.setState({
                                                  selectendDate: false,
                                                  selectstartData: true
                                              })}
                                                      className="relative inline-flex items-center w-1/2 px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                  {currentDates.startDate ? currentDates.startDate.toLocaleDateString() : 'Start Datum'}
                                              </button>

                                              <button type="button" onClick={() => this.setState({
                                                  selectendDate: true,
                                                  selectstartData: false
                                              })}
                                                      className="-ml-px relative inline-flex items-center w-1/2 px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                                  {currentDates.endDate ? currentDates.endDate.toLocaleDateString() : 'Ende Datum'}
                                              </button>
                                            </span>

                                {this.state.selectstartData ? (
                                    <DayPicker
                                        className="border-2 rounded"
                                        selectedDays={currentDates.startDate}
                                        onDayClick={(day, {selected}) => this.onStartDayClick(day, selected)}
                                        modifiers={{
                                            highlighted: currentDates.endDate,
                                        }}
                                        disabledDays={[
                                            {
                                                after: new Date(),
                                            },
                                        ]}
                                    />) : (<></>)}
                                {this.state.selectendDate ? (
                                    <DayPicker
                                        className="border-2 rounded"
                                        selectedDays={currentDates.endDate}
                                        onDayClick={(day, {selected}) => this.onEndDayClick(day, selected)}
                                        modifiers={{
                                            highlighted: currentDates.startDate,
                                        }}
                                        disabledDays={[
                                            {
                                                after: new Date(),

                                            },
                                        ]}
                                    />) : (<></>)}

                                <style>{birthdayStyle}</style>

                            </div>

                            <div className="flex flex-col space-y-3">
                                <div className="col-lg-12">
                                    <h5 className=" text-secondary" style={{

                                        width: "100%",
                                        "marginBottom": "13px"

                                    }}>Vorauswahl:</h5>
                                </div>

                                <button type="button"
                                        onClick={() => this.changeIntervall("1h", "1 Stunde")}
                                        className={"inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none "}>Letzte
                                    Stunde
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("today", "Heute")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Heute
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("1w", "Diese Woche")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Diese Woche
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("3h", "Letzte 3 Stunde")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Letzte 3 Stunden
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("yesterday", "Gestern")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Gestern
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("1m", "Dieser Monat")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Dieser Monat
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("6h", "Letzte 6 Stunde")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Letzte 6 Stunden
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("daybeforeyesterday", "Vorgestern")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Vorgestern
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("1j", "Dieses Jahr")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Dieses Jahr
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("12h", "Letzten 12 Stunde")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Letzte 12 Stunden
                                </button>
                                <button type="button"
                                        onClick={() => this.changeIntervall("24h", "Letzten 24 Stunde")}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                                    Letzte 24 Stunden
                                </button>

                                {/*<div className="col-lg-4">*/}
                                {/*    <a className="card" style={{cursor: "pointer"}} href={"# "}*/}
                                {/*       onClick={() => this.changeIntervall(this, this.props.datacolums[0].colum, this.props.datacolums[1].colum, this.props.datacolums[2].colum, "live", "Live Data", true, true)}>*/}

                                {/*        <div className="text-secondary">Live Data</div>*/}
                                {/*    </a>*/}
                                {/*</div>*/}


                            </div>


                        </div>
                    )}
                </Transition>
            </div>
        );
    }
}

TimeWindowSelection.propTypes = {};

export default TimeWindowSelection;