import React, {Component} from 'react';
import Switch from "./Switch";
import TextInput from "../ERPImport/TextInput";

class DisplaySetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false
        }
    }

    displayDatatype(value) {
        if (this.state.isEditMode) {

            switch (this.props.datatype) {
                case "bool":
                    return (<Switch text={((value === "true") ? ("JA") : ("NEIN"))}
                                    value={(value === "true")}
                                    onChange={event => {
                                        if (this.props.onChange !== undefined) {
                                            this.props.onChange((!(value === "true")).toString())
                                        }
                                    }}/>)
                case "string":
                    return (<TextInput
                                       value={(value !== "undefined" ? value : "NOT SET")}
                                       onChange={event => {
                                           if (this.props.onChange !== undefined) {
                                               this.props.onChange(event.toString())
                                           }
                                       }}/>)

            }

        } else {

            switch (this.props.datatype) {
                case "bool":
                    return ((value === "true") ? ("JA") : ("NEIN"))
                case "string":
                    return (value !== "undefined" ? value : "NOT SET")
            }


        }
    }


    render() {
        return (
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500">
                    {this.props.name}
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">{this.displayDatatype(this.props.value)}</span>
                    <span className="ml-4 flex-shrink-0">
                    <button type="button" onClick={() => this.setState((state) => ({isEditMode: !state.isEditMode}))}
                            className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                      Change
                    </button>
                  </span>
                </dd>
            </div>
        );
    }
}

DisplaySetting.propTypes = {};

export default DisplaySetting;