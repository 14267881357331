import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CircleWithLetter extends Component {
    render() {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                     style={{width: this.props.width, height: this.props.height}} viewBox="-0.5 -0.5 81 81"
                     content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-06-07T06:10:58.336Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;WZEqKfIZihSAatb2vRIb&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;SP7OBU8NKbph_0176BNj&quot; name=&quot;Page-1&quot;>jZNRT8IwEMc/zR5JWGtAHwUmRqPGLMHEt7Ida5OOjq6w4af3RtttlZD4Ane/Xv+99v6L6LJs15pV/E3lICMyzduIriJC6OwBfztwdoASCwotcoviAaTiBxycOnoUOdRBoVFKGlGFMFP7PWQmYExr1YRlOyXDUytWwBVIMyav6ZfIDbf0nswH/gyi4P7k2F+4ZL7Y3aTmLFfNCNEkokutlLFR2S5Bdm/n38Xue7qx2jemYW/+syFh/OP9+3PzulnJdHN4mdc0njiVE5NHd+GIzCTqLXYKZbFrP5HZ4dh1uoiHEKPC/V+2bLt6c5bhhk5oYmUesYBOq/ZaYu01sP/tX11kthuPSXAQwXuiHTBZNFwYSCuWdSsNGhIZN6XELMaQ1ZX1yE60kPdSJ9AG2pvPGvfDQpODKsHoM5Z4h8/dfJ3B73zejOziEB85xTPmDFr0ysMMMXBj9Olgl8va6JujyS8=</diagram></mxfile>"
                >
                    <defs/>
                    <g>
                        <ellipse cx={40} cy={40} rx={40} ry={40} fill={"rgb(255,255,255)"} stroke="rgb(215, 215, 215)"
                                 className={"border-2 "}
                                 pointerEvents="all"/>
                        <g transform="translate(-0.5 -0.5)">
                            <switch>
                                <foreignObject pointerEvents="none" width="100%" height="100%"
                                               requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
                                               style={{overflow: 'visible', textAlign: 'left'}}>
                                    <div xmlns="http://www.w3.org/1999/xhtml" style={{
                                        display: 'flex',
                                        alignItems: 'unsafe center',
                                        justifyContent: 'unsafe center',
                                        width: '78px',
                                        height: '1px',
                                        paddingTop: '40px',
                                        marginLeft: '1px'
                                    }}>
                                        <div data-drawio-colors="color: rgb(0, 0, 0); "
                                             style={{boxSizing: 'border-box', fontSize: '0px', textAlign: 'center'}}>
                                            <div className={" font-sans "} style={{
                                                display: 'inline-block',
                                                fontSize: '12px',
                                                color: 'rgb(62,61,61)',
                                                lineHeight: '1.2',
                                                pointerEvents: 'all',
                                                whiteSpace: 'normal',
                                                overflowWrap: 'normal'
                                            }}><font size={1}><b
                                                style={{fontSize: '30px'}}>{this.props.letter}</b></font></div>
                                        </div>
                                    </div>
                                </foreignObject>
                                <text x={40} y={44} fill="rgb(0, 0, 0)" fontFamily="Helvetica" fontSize="12px"
                                      textAnchor="middle">G
                                </text>
                            </switch>
                        </g>
                    </g>
                    <switch>
                        <g requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"/>
                        <a transform="translate(0,-5)"
                           xlinkHref="https://www.diagrams.net/doc/faq/svg-export-text-problems" target="_blank">
                            <text textAnchor="middle" fontSize="10px" x="50%" y="100%">Text is not SVG - cannot
                                display
                            </text>
                        </a></switch>
                </svg>

            </div>
        );
    }
}

CircleWithLetter.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    letter: PropTypes.string
};

export default CircleWithLetter;