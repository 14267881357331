import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ControlGraphic from "../pages/ControlGraphic";
import InteractiveGraphic from "../components/InteractiveGraphic";
import ArlmuehleBackground from "../circuitSymbols/ArlmuehleBackground";
import ArlmuehleGraphic from "../InteractiveGraphics/ArlmuehleGraphic";
import StorageController from "../components/StorageController";

class ArlmuehlePage extends Component {
    render() {
        return (
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Arlmühle</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-4">


                    <InteractiveGraphic background={<ArlmuehleBackground width={"1100px"} height={"1100px"}/>}
                                        graphicElements={ArlmuehleGraphic}/>

                    <StorageController titel={"Speicher Arlmühle"} deviceName={"ereneo-monitoring-node-arlmuehle"} deviceId={"20"}  />

                </div>
            </div>
        );
    }
}

ArlmuehlePage.propTypes = {};

export default ArlmuehlePage;