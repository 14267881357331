import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PageTitle extends Component {
    render() {
        return (
            <div className={"col-span-12 pt-5"}>
                <div className="border-b border-gray-200">
                    <div className="sm:flex sm:items-baseline">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{this.props.titel}</h3>
                        <div className="mt-4 sm:mt-0 sm:ml-10">
                            <nav className="-mb-px flex space-x-8">
                                {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
                                <a href="#" className="border-indigo-500 text-indigo-600 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm" aria-current="page"> Übersicht </a>
                                {/*<a href="#" className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"> Vergleiche </a>*/}
                                {/*<a href="#" className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"> Erweitert </a>*/}
                            </nav>
                        </div>
                    </div>
                </div>

                {/*<div className="md:flex md:items-center md:justify-between">*/}
                {/*    <div className="flex-1 min-w-0">*/}
                {/*        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{this.props.titel}</h2>*/}
                {/*    </div>*/}
                {/*    <div className="mt-4 flex md:mt-0 md:ml-4">*/}
                {/*        <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Edit</button>*/}
                {/*        <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Publish</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

PageTitle.propTypes = {
    titel:PropTypes.string.isRequired
};

export default PageTitle;