import React, {Component} from 'react';
import PropTypes from "prop-types";

class BetriebszentraleBackground extends Component {
    render() {
        return (<>
                {/*?xml version="1.0" encoding="UTF-8"?*/}
                {/* Do not edit this file with editors other than diagrams.net */}
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                     width={this.props.width} height={this.props.height} viewBox="-0.5 -0.5 922 602"
                     content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-06-06T08:57:29.639Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;P_5Ruu0zpKo5UkyzZIud&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;h5EHXLx7pRkKu_khZ0wB&quot; name=&quot;Page-1&quot;>7ZjLcpswFIafhqU7QuLmZYtdZ6bJpDPuZbLUWAowAeQKOb48feUiGRB17NrgLog3Rj+Ho5+jb44AC4XZZsbxMn5ghKYWBGRjoYkFIQzGSP7tlW2p2LYLSyXiCVFaJcyTHVUiUOoqIbRoBArGUpEsm+KC5TldiIaGOWfrZtgzS5uzLnFEW8J8gdO2+jMhIi7VAPqVfkeTKNYz2964PJNhHazupIgxYeuahKYWCjljojzKNiFN99XTdSmv+3zk7MEYp7k454LHjDyF4e4umt6vv3x/+eGTYjdyyyyvOF2pG1ZmxVZXgObk476QcpSzXIqfYpGlcmTLQ85WOaFEjdqWlEtKGlVWBmeUZVTwrQxYV7V1Vb3iWlm1xmmKRfLaXBusljg6pDvM8JUl0gkEikdHpVEwehoynaFgK76g6qJ6KY08rtdMhKCRSGAeUdFKJOuIt7Ww5T6gONuvnvaYLdu7Lh41CZIHpWE9qq1YJf2h7B+I8wZNXHAhcUaew8r1C5wDbgCEP2QgnEuBGAX/hwhzkzlhy7lFSxkPi6Cgo54SACMR6AchF/7d8NHd1Yy/RRfSjwLvEF0Hkd9TH7oWIt+7BUT2sCAa99WJzERdQYTehKgnKOCwoDDWsvVudC4U0HyUcPqBwjSs5+kKivkEfPNj+GsW7568yfMjeJjcj4bFBIIdMWE2CgjAB1D7Gc46IsS0D8GJvcd7M74fotCgiJJdtas361NPQh1BdGSvvJAJOay+HJbh1QdYNP0N</diagram></mxfile>"
                     style={{backgroundColor: 'rgb(255, 255, 255)'}}>
                    <defs/>
                    <g>
                        <path
                            d="M 160 450 L 160 410 Q 160 400 170 400 L 270 400 Q 280 400 280 390 L 280 170 Q 280 160 290 160 L 680 160"
                            fill="none" stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 160 520 L 160 250 Q 160 240 160 230 L 160 0" fill="none" stroke="rgb(0, 0, 0)"
                              strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 160 320 L 160 270 Q 160 260 150 260 L 50 260 Q 40 260 40 250 L 40 0" fill="none"
                              stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 600 520 L 630 520 Q 640 520 640 510 L 640 450 Q 640 440 650 440 L 920 440"
                              fill="none" stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 600 520 L 630 520 Q 640 520 640 530 L 640 590 Q 640 600 650 600 L 920 600"
                              fill="none" stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 610 520 L 640 520 Q 650 520 660 520 L 920 520" fill="none" stroke="rgb(0, 0, 0)"
                              strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 520 160 L 520 270 Q 520 280 510 280 L 400 280" fill="none" stroke="rgb(0, 0, 0)"
                              strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 440 160 L 440 50 Q 440 40 450 40 L 670 40 Q 680 40 690 40 L 920 40" fill="none"
                              stroke="rgb(0, 0, 0)" strokeMiterlimit={10} pointerEvents="stroke"/>
                        <path d="M 0 520 L 200 520 Q 210 520 220 520 L 600 520" fill="none" stroke="rgb(0, 0, 0)"
                              strokeMiterlimit={10} pointerEvents="stroke"/>
                    </g>
                </svg>


            </>

        );
    }
}

BetriebszentraleBackground.propTypes = {
    width: PropTypes.string, height: PropTypes.string,
};

export default BetriebszentraleBackground;