import React, {Component} from 'react';
import DisplaySetting from "./DisplaySetting";
import {Settings} from "../APIClient";

class DeviceConfig extends Component {
    constructor() {
        super();
        this.state = {
            settings: []


        }
    }

    componentDidMount() {
        Settings.get().then((data) => {
            this.setState({settings: data})
        })
    }


    render() {
        return (
            <>
                {/* Description list with inline editing */}
                <div className="mt-10 divide-y divide-gray-200">
                    <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Devices
                        </h3>
                    </div>
                    <div className="mt-6">
                        <dl className="divide-y divide-gray-200">

                            {this.state.settings.map((setting, index) => {

                                return (<DisplaySetting name={setting.settingName} datatype={setting.settingDatatype}
                                                        value={setting.settingValue}
                                                        onChange={(value) => {
                                                            this.setState((state) => {
                                                                let settings = state.settings
                                                                settings[index].settingValue = value
                                                                return ({settings})
                                                            })
                                                            Settings.setSetting(value, setting.settingName, setting.settingScope)
                                                        }}/>)
                            })}

                        </dl>
                    </div>
                </div>
            </>
        );
    }
}

DeviceConfig.propTypes = {};

export default DeviceConfig;