import React, {Component} from 'react';
import PropTypes from "prop-types";

class Group extends Component {
    render() {
        return (<div style={{width: this.props.width, height: this.props.height,backgroundColor:this.props.backgroundColor}}
                     className={"bg-gray-300 border-2 border-gray-200  rounded-md hover:border-2 hover:border-gray-400 hover:border-dashed bg-opacity-20  "}>
            <div className={"flex justify-center pt-1.5 "}><h1 className={"font-semibold"}> {this.props.text}</h1></div>
        </div>)
    }
}

Group.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    text: PropTypes.string,
    backgroundColor: PropTypes.string,

};

export default Group;