import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Database} from "../APIClient";
import InfiniteScroll from "react-infinite-scroll-component";
import DisplayValue from "./DisplayValue";

class MilestoneTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: {}

        }
    }


    render() {
        return (
            <div>


                <table className="shadow  border-b border-gray-200     w-full">
                    <thead>
                    <tr>
                        <th scope="col" style={{color: "#dee2e6"}}/>
                        <th scope="col" style={{border: "2px solid #dee2e6"}}
                            className={"px-2 py-2 text-left text-sm font-semibold text-gray-700  tracking-wider"}>Heute
                        </th>
                        <th scope="col" style={{border: "2px solid #dee2e6"}}
                            className={"px-2 py-2 text-left text-sm font-semibold text-gray-700  tracking-wider"}>Gestern
                        </th>
                        <th scope="col" style={{border: "2px solid #dee2e6"}}
                            className={"px-2 py-2 text-left text-sm font-semibold text-gray-700  tracking-wider"}>Dieser
                            Monat
                        </th>
                        <th scope="col" style={{border: "2px solid #dee2e6"}}
                            className={"px-2 py-2 text-left text-sm font-semibold text-gray-700  tracking-wider"}>Letzter
                            Monat
                        </th>
                        <th scope="col" style={{border: "2px solid #dee2e6"}}
                            className={"px-2 py-2 text-left text-sm font-semibold text-gray-700  tracking-wider"}>Dieses
                            Jahr
                        </th>
                        <th scope="col" style={{border: "2px solid #dee2e6"}}
                            className={"px-2 py-2 text-left text-sm font-semibold text-gray-700  tracking-wider"}>Letztes
                            Jahr
                        </th>

                    </tr>
                    </thead>

                    <tbody>


                    <tr>
                        <th scope="row "
                            className={"px-5 py-3 text-left text-sm font-semibold text-gray-700  tracking-wider"}
                            style={{border: "2px solid #dee2e6"}}>Netzbezug:
                        </th>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_sum.today}+{AGG." + this.props.deviceString+".kWhposL2c_sum.today}+{AGG." + this.props.deviceString+".kWhposL3c_sum.today}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_sum.yesterday}+{AGG." + this.props.deviceString+".kWhposL2c_sum.yesterday}+{AGG." + this.props.deviceString+".kWhposL3c_sum.yesterday}"}/>kWh
                        </td>

                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_sum.thisMonth}+{AGG." + this.props.deviceString+".kWhposL2c_sum.thisMonth}+{AGG." + this.props.deviceString+".kWhposL3c_sum.thisMonth}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_sum.thisYear}+{AGG." + this.props.deviceString+".kWhposL2c_sum.thisYear}+{AGG." + this.props.deviceString+".kWhposL3c_sum.thisYear}"}/>kWh

                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_sum.thisYear}+{AGG." + this.props.deviceString+".kWhposL2c_sum.lastYear}+{AGG." + this.props.deviceString+".kWhposL3c_sum.thisYear}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_sum.lastYear}+{AGG." + this.props.deviceString+".kWhposL2c_sum.lastYear}+{AGG." + this.props.deviceString+".kWhposL3c_sum.lastYear}"}/>kWh
                        </td>
                    </tr>


                    <tr>
                        <th scope="row"
                            className={"px-5 py-3 text-left text-sm font-semibold text-gray-700  tracking-wider"}
                            style={{border: "2px solid #dee2e6"}}> Netzeinspeisung:
                        </th>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            -<DisplayValue
                            value={"{AGG." + this.props.deviceString+".kWhnegL1c_sum.today}+{AGG." + this.props.deviceString+".kWhnegL2c_sum.today}+{AGG." + this.props.deviceString+".kWhnegL3c_sum.today}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            -<DisplayValue
                            value={"{AGG." + this.props.deviceString+".kWhnegL1c_sum.yesterday}+{AGG." + this.props.deviceString+".kWhnegL2c_sum.yesterday}+{AGG." + this.props.deviceString+".kWhnegL3c_sum.yesterday}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            -<DisplayValue
                            value={"{AGG." + this.props.deviceString+".kWhnegL1c_sum.thisMonth}+{AGG." + this.props.deviceString+".kWhnegL2c_sum.thisMonth}+{AGG." + this.props.deviceString+".kWhnegL3c_sum.thisMonth}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            -<DisplayValue
                            value={"{AGG." + this.props.deviceString+".kWhnegL1c_sum.thisMonth}+{AGG." + this.props.deviceString+".kWhnegL2c_sum.thisMonth}+{AGG." + this.props.deviceString+".kWhnegL3c_sum.thisMonth}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            -<DisplayValue
                            value={"{AGG." + this.props.deviceString+".kWhnegL1c_sum.thisYear}+{AGG." + this.props.deviceString+".kWnegL2c_sum.thisYear}+{AGG." + this.props.deviceString+".kWhnegL3c_sum.thisYear}"}/>kWh
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            -<DisplayValue
                            value={"{AGG." + this.props.deviceString+".kWhnegL1c_sum.lastYear}+{AGG." + this.props.deviceString+".kWhnegL2c_sum.lastYear}+{AGG." + this.props.deviceString+".kWhnegL3c_sum.lastYear}"}/>kWh
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={"px-5 py-3 text-left text-sm font-semibold text-gray-700  tracking-wider"} style={{border: "2px solid #dee2e6"}}>Min:</th>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_max.today}"}/>
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                            <DisplayValue
                                value={"{AGG." + this.props.deviceString+".kWhposL1c_max.yesterday}"}/>
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>

                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>

                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>
                        </td>
                        <td className={"px-3 py-1 text-left text-sm font-mono text-gray-700  tracking-wider border"}>

                        </td>
                    </tr>
                    {/*<tr>*/}
                    {/*    <th scope="row" style={{border: "2px solid #dee2e6"}}>Max:</th>*/}
                    {/*    <td>*/}
                    {/*        {parseFloat(this.state.minmaxData.max).toFixed(1)} W*/}
                    {/*    </td>*/}
                    {/*    <td style={{border: "1px solid #dee2e6"}}>*/}
                    {/*        {parseFloat(this.state.minmaxData.max_gest).toFixed(1)} W*/}
                    {/*    </td>*/}
                    {/*    <td style={{border: "1px solid #dee2e6"}}>*/}
                    {/*        {parseFloat(this.state.minmaxData.max_thismoth).toFixed(1)} W*/}
                    {/*    </td>*/}
                    {/*    <td style={{border: "1px solid #dee2e6"}}>*/}
                    {/*        {parseFloat(this.state.minmaxData.max_lastmoth).toFixed(1)} W*/}
                    {/*    </td>*/}
                    {/*    <td/>*/}
                    {/*    <td style={{border: "1px solid #dee2e6"}}>*/}
                    {/*    </td>*/}

                    {/*</tr>*/}

                    </tbody>
                </table>


            </div>
        );
    }
}

MilestoneTable.propTypes = {
    tableName: PropTypes.string.isRequired
};

export default MilestoneTable;