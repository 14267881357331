import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InteractiveGraphic from "../components/InteractiveGraphic";
import ContentFitComponent from "../ERPImport/ContentFitComponent";
import ArlmuehleBackground from "../circuitSymbols/ArlmuehleBackground";
import ArlmuehleGraphic from "../InteractiveGraphics/ArlmuehleGraphic";
import BetriebszentraleBackground from "../circuitSymbols/BetriebszentraleBackground";
import BetriebszentraleGraphic from "../InteractiveGraphics/BetriebszentraleGraphic";
import KundencenterGraphic from "../InteractiveGraphics/KundencenterGraphic";
import KundencenterBackground from "../circuitSymbols/KundencenterBackground";
import SteuerbildBackground from "../circuitSymbols/SteuerbildBackground";
import SteuerbildGraphic from "../InteractiveGraphics/SteuerbildGraphic";
import ControlGraphic from "./ControlGraphic";
import {GiWaterMill} from "react-icons/gi";
import {Link} from "react-router-dom";
import {TbInfoSquare} from "react-icons/tb";
import {BiBuildings} from "react-icons/bi";

class Startpage extends Component {
    render() {
        return (
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 ">

                    <div className="max-w-6xl mt-7 ">
                        <h2 className="text-lg leading-6 font-medium text-gray-900"></h2>
                        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                            {/* Card */}
                            <div className="bg-white overflow-hidden shadow rounded-lg">
                                <div className="p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <GiWaterMill className="h-6 w-6 text-gray-400"/>
                                        </div>
                                        <div className="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt className="text-sm font-medium text-gray-900 truncate">Arlmühle</dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-5 py-3">
                                    <Link to={"/device/ereneo-monitoring-node-arlmuehle"} className="text-sm">
                                        <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900"> View </a>
                                    </Link>
                                </div>
                            </div>
                            <div className="bg-white overflow-hidden shadow rounded-lg">
                                <div className="p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <BiBuildings className="h-6 w-6 text-gray-400"/>
                                        </div>
                                        <div className="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt className="text-sm font-medium text-gray-900 truncate">Betriebszentrale</dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-5 py-3">
                                    <Link to={"/device/ereneo-monitoring-node-betriebszentrale"} className="text-sm">
                                        <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900"> View </a>
                                    </Link>
                                </div>
                            </div>
                            <div className="bg-white overflow-hidden shadow rounded-lg">
                                <div className="p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <TbInfoSquare className="h-6 w-6 text-gray-400"/>
                                        </div>
                                        <div className="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt className="text-sm font-medium text-gray-900 truncate">Kundencenter</dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-5 py-3">
                                    <Link to={"/device/ereneo-monitoring-node-kundencenter"} className="text-sm">
                                        <a href="#" className="font-medium text-cyan-700 hover:text-cyan-900"> View </a>
                                    </Link>
                                </div>
                            </div>
                            {/* More items... */}
                        </div>
                    </div>


                    {/*<svg viewBox="0 0 742.1 874.8" height="100%" width="100%">*/}
                    {/*    <style dangerouslySetInnerHTML={{__html: "\n       .draw {stroke-dasharray: 1,70; animation: dashdraw 4s linear infinite;}\n       @keyframes dashdraw {to {stroke-dashoffset: 400;}}\n    " }} />*/}
                    {/*    <path x1="40" x2="260" y1="100" y2="100" stroke="#5184AF" stroke-width="20" stroke-linecap="round" stroke-dasharray="1, 30" className="draw" strokeLinejoin="round" stroke="darkslategray" fill="none" strokeWidth={10} d="m371.4 138a142.5 142.5 0 0 0 -142.4 142.8 142.5 142.5 0 0 0 72 123.4l-88.8 241.7h-114.4v150h547.2v-150h-114l-88.9-241.7a142.5 142.5 0 0 0 72.4 -123.4 142.5 142.5 0 0 0 -143.1 -142.8z" />*/}
                    {/*    <path className="draw" id="lineAB" d="M 100 350 l 150 -300" stroke="red"*/}
                    {/*          stroke-width="3" fill="none" />*/}
                    {/*    <line className="draw" x1="40" x2="260" y1="100" y2="100" stroke="#5184AF" stroke-width="20" stroke-linecap="round" stroke-dasharray="1, 30"/>*/}

                    {/*</svg>*/}


                    {/*<ControlGraphic/>*/}

                    {/*<InteractiveGraphic background={<ArlmuehleBackground width={"1100px"} height={"1100px"}/>}*/}
                    {/*                    graphicElements={ArlmuehleGraphic}/>*/}
                    {/*<InteractiveGraphic background={<BetriebszentraleBackground width={"1100px"} height={"1100px"}/>}*/}
                    {/*                    graphicElements={BetriebszentraleGraphic}/>*/}
                    {/*<InteractiveGraphic background={<KundencenterBackground width={"1100px"} height={"1100px"}/>}*/}
                    {/*                    graphicElements={KundencenterGraphic}/>*/}


                    {/*<InteractiveGraphic background={<SteuerbildBackground width={"1100px"} height={"1100px"}/>}*/}
                    {/*                    graphicElements={SteuerbildGraphic}/>*/}

                    {/*<ContentFitComponent>*/}


                    {/*    <InteractiveGraphic/>*/}
                    {/*</ContentFitComponent>*/}
                </div>
            </div>
        );
    }
}

Startpage.propTypes = {};

export default Startpage;