import React, {Component} from 'react';
import ControlGraphic from "./ControlGraphic";


class ControlFlow extends Component {
    render() {
        return (
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Control Flow</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-4">


                    <ControlGraphic/>

                </div>
            </div>
        );
    }
}

ControlFlow.propTypes = {};

export default ControlFlow;