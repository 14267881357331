import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Chart from "../components/Chart";
import Graph from "../components/Graph";
import PageTitle from "../components/PageTitle";
import {IoChevronDownOutline} from "react-icons/io5";
import PhaseIconTotal from "../images/icons/PhaseIconTotal.svg"
import PhaseIconL1 from "../images/icons/PhaseIconL1.svg"
import PhaseIconL2 from "../images/icons/PhaseIconL2.svg"
import PhaseIconL3 from "../images/icons/PhaseIconL3.svg"
import MilestoneTable from "../components/MilestoneTable";
import DisplayValue from "../components/DisplayValue";
import ChartContainer from "../components/ChartContainer";
import availableDevices from "../Devices";

class Device extends Component {
    constructor(props) {
        super(props);

        let currentDevice = availableDevices.filter(item => (item.deviceId === (props.installationId + "/" + props.deviceType + "/" + props.deviceId)))[0]
        this.state = {
            availableDevices: availableDevices,
            currentDevice: currentDevice || {},
            deviceNotFound: (currentDevice === undefined)
        }

    }

    render() {
        if (this.state.deviceNotFound) {
            return (<div className={"flex-1 grid gap-6 grid-cols-12  auto-rows-fr p-8"}>
                The device "{this.props.installationId + "/" + this.props.deviceType + "/" + this.props.deviceId}" was
                not found.

            </div>)

        }
        let deviceString = this.state.currentDevice.deviceId.replaceAll("/", ".")

        return (
            <div className={"flex-1 grid gap-6 grid-cols-12  auto-rows-fr p-8"}
                 style={{gridAutoRows: 'minmax(0, 0.25fr)'}}>

                <PageTitle titel={this.state.currentDevice.deviceName}/>

                <ChartContainer deviceString={deviceString}/>


                <div
                    className={"col-span-3 row-span-1 relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400"}>
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-md" src={PhaseIconTotal} alt=""/>
                    </div>
                    <div className="flex-1 min-w-0">
                        <a href="#" className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true"/>
                            <p className="text-sm font-medium text-gray-900">GESAMTLEISTUNG:</p>
                            <p className="text-sm text-gray-500 truncate"><DisplayValue
                                value={"{" + deviceString + ".kWL1}+{" + deviceString + ".kWL2}+{" + deviceString + ".kWL3}"}/>W
                            </p>
                        </a>
                    </div>
                </div>
                <div
                    className={"col-span-3 row-span-1 relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400"}>
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-md" src={PhaseIconL1} alt=""/>
                    </div>
                    <div className="flex-1 min-w-0">
                        <a href="#" className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true"/>
                            <p className="text-sm font-medium text-gray-900">PHASE 1:</p>
                            <p className="text-sm text-gray-500 truncate"><DisplayValue
                                value={"{" + deviceString + ".kWL1}"}/>W</p>
                        </a>
                    </div>
                </div>
                <div
                    className={"col-span-3 row-span-1 relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400"}>
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-md" src={PhaseIconL2} alt=""/>
                    </div>
                    <div className="flex-1 min-w-0">
                        <a href="#" className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true"/>
                            <p className="text-sm font-medium text-gray-900">PHASE 2:</p>
                            <p className="text-sm text-gray-500 truncate"><DisplayValue
                                value={"{" + deviceString + ".kWL2}"}/>W</p>
                        </a>
                    </div>
                </div>
                <div
                    className={"col-span-3 row-span-1 relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400"}>
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-md" src={PhaseIconL3} alt=""/>
                    </div>
                    <div className="flex-1 min-w-0">
                        <a href="#" className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true"/>
                            <p className="text-sm font-medium text-gray-900">PHASE 3:</p>
                            <p className="text-sm text-gray-500 truncate"><DisplayValue
                                value={"{" + deviceString + ".kWL3}"}/>W</p>
                        </a>
                    </div>
                </div>
                {/*<div*/}
                {/*    className="col-span-12 row-span-6 bg-white overflow-hidden shadow-lg border-2 border-gray-200 rounded-md divide-y divide-gray-200">*/}
                {/*    <div className="px-2 py-3 sm:px-6">*/}
                {/*        <div className="md:flex md:items-center md:justify-between">*/}
                {/*            <div className="flex-1 min-w-0">*/}
                {/*                Energie*/}
                {/*            </div>*/}
                {/*            <div className="mt-4 flex md:mt-0 md:ml-2">*/}


                {/*                /!*<button type="button"*!/*/}
                {/*                /!*        className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*!/*/}
                {/*                /!*    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"*!/*/}
                {/*                /!*         fill="currentColor" aria-hidden="true">*!/*/}
                {/*                /!*        <path fill-rule="evenodd"*!/*/}
                {/*                /!*              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"*!/*/}
                {/*                /!*              clip-rule="evenodd"/>*!/*/}
                {/*                /!*    </svg>*!/*/}
                {/*                /!*</button>*!/*/}
                {/*                /!*<button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Edit</button>*!/*/}
                {/*                /!*<button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Publish</button>*!/*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="px-4 py-5 sm:p-6">*/}
                {/*        <MilestoneTable deviceString={deviceString}/>*/}

                {/*    </div>*/}
                {/*</div>*/}

            </div>
        );
    }
}

Device.propTypes = {};

export default Device;