import React, {Component} from 'react';
import PropTypes from "prop-types";

class ElectricityMeter extends Component {
    render() {

        return (
            <div
                style={{width: this.props.width, height: this.props.height}}
                className={"flex flex-col  "}>
                <div className={"flex justify-center pt-1.5 h-6 border-b-2 border-gray-200"}>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                         width="82px"
                         height="111px" viewBox="-0.5 -0.5 82 111"
                         content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-06-03T12:14:17.083Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;dvZ_FeNz98yL8KhPVdix&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;wX7cE2BQyfxsg2WkTRHv&quot; name=&quot;Page-1&quot;>lVTBboMwDP0ajpUK2Up33Npt3WHSpB7WHiPiQjRIUJoW2NcvLA4QaNXtguxnx7GfHwnIqqhfFS2zd8kgD6I5qwOyDqKIzGPzbYHGAlGMQKo4s1DYA1v+DQjOET1xBkcvUUuZa176YCKFgER7GFVKVn7aQeb+rSVNYQJsE5pP0U/OdGbRZRT3+AZ4mrmbw8WDjRTUJeMkx4wyWQ0g8hyQlZJSW6uoV5C33Dle7LmXK9GuMQVC/+XAMgph8/SyE2/Z7OMgYP+1SGdY5UzzEw6MzerGMaDkSTBoi8wD8lRlXMO2pEkbrczKDZbpIjdeaEwsB0pDfbXPsJveqAZkAVo1JsVJxhGGiiEL9KsB/whlHvUIUlx52pXuWTEGEvMPkshtkkCwx1ZtxhNSgE+Kz6ChQTU7DP06+2FkXXte47ya693A3g/s/kjruBO2R2ATfY8WYeaQJ5XAbZVoqlLQt/Kmix0s7v7C4hymIKean/12Ly0Tb/iQ3AzS6SaKR7qJR3qwY+Kp4Y8yKjQRYDQqZHmYFDICoM0grWwTjtcb7oSN99yF3g9sDFuxF27H6SUtG7d/TGx6/yKT5x8=</diagram></mxfile>"
                         style={{backgroundColor: 'rgb(255, 255, 255)'}}>
                        <defs/>
                        <g>
                            <rect x={0} y={0} width={80} height={110} fill="rgb(255, 255, 255)" stroke="rgb(0, 0, 0)"
                                  pointerEvents="all"/>
                            <path d="M 0 0 L 40 50 L 80 0" fill="none" stroke="rgb(0, 0, 0)" strokeMiterlimit={10}
                                  pointerEvents="stroke"/>
                        </g>
                    </svg>
                </div>
            </div>
        )





    }
}


ElectricityMeter.propTypes = {
    scale: PropTypes.string,

};

export default ElectricityMeter;