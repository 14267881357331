import DisplayValue from "../components/DisplayValue";
import React from "react";
import PageTitle from "../components/PageTitle";

const ArlmuehleGraphic = [{
    "id": "86e16a75-1c0a-4c40-9a3d-ef3dcd08d236",
    "x": 504,
    "y": 199,
    "width": 449,
    "height": 386,
    "typ": "group",
    "color": "rgb(209 213 219 / 0.3)",
    "text": "Schaltkasten \"Standortknoten Arlmühle\"",
    "ds": false
}, {
    "id": "5868a2e9-2e80-4607-950a-99fefad248cb",
    "x": 962,
    "y": 199,
    "width": 234,
    "height": 151,
    "typ": "group",
    "color": "rgb(2 132 199 / 0.3)",
    "text": "ereneo storage",
    "ds": false
}, {
    "id": "78ffb997-6bd5-4d1a-afbf-1d1be4a405b8",
    "x": 165,
    "y": 289,
    "width": 30,
    "height": 30,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-arlmuehle.CGET340.24.kWL1} + {ereneo-monitoring-node-arlmuehle.CGET340.24.kWL2} +{ereneo-monitoring-node-arlmuehle.CGET340.24.kWL3}",
    "text": "24",
    "link": "/device/ereneo-monitoring-node-arlmuehle/CGET340/24",
    "ds": false
}, {
    "id": "d13f1140-f7b7-4d6d-89a4-d2f9075af7eb",
    "x": 727,
    "y": 241,
    "width": 30,
    "height": 30,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-arlmuehle.CGET340.20.kWL1} + {ereneo-monitoring-node-arlmuehle.CGET340.20.kWL2} +{ereneo-monitoring-node-arlmuehle.CGET340.20.kWL3}",
    "text": "20",
    "link": "/device/ereneo-monitoring-node-arlmuehle/CGET340/20",
    "ds": false
}, {
    "id": "497575c2-db2d-4d13-9d7f-1082ce271cf1",
    "x": 328,
    "y": 101,
    "width": 30,
    "height": 30,
    "typ": "measuringPoint",
    "text": "23",
    "value": "{ereneo-monitoring-node-arlmuehle.CGET340.23.kWL1} + {ereneo-monitoring-node-arlmuehle.CGET340.23.kWL2} +{ereneo-monitoring-node-arlmuehle.CGET340.23.kWL3}",
    "link": "/device/ereneo-monitoring-node-arlmuehle/CGET340/23",
    "ds": false
}, {
    "id": "94c7d670-4780-4bfa-a9d5-14bfcf1139a0",
    "x": 727,
    "y": 740,
    "width": 30,
    "height": 30,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-arlmuehle.CGET340.22.kWL1} + {ereneo-monitoring-node-arlmuehle.CGET340.22.kWL2} +{ereneo-monitoring-node-arlmuehle.CGET340.22.kWL3}",
    "text": "22",
    "link": "/device/ereneo-monitoring-node-arlmuehle/CGET340/22",
    "ds": false
}, {
    "id": "4a7e4dc6-7868-4246-b9b9-afb7f11180ab",
    "x": 724,
    "y": 624,
    "width": 30,
    "height": 30,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-arlmuehle.CGET340.21.kWL1} + {ereneo-monitoring-node-arlmuehle.CGET340.21.kWL2} +{ereneo-monitoring-node-arlmuehle.CGET340.21.kWL3}",
    "link": "/device/ereneo-monitoring-node-arlmuehle/CGET340/21",
    "text": "21",
    "ds": false
}, {
    "id": "23107091-1e61-4265-b0f2-9c5d6e74188f",
    "x": 1009,
    "y": 589,
    "width": 138,
    "height": 110,
    "typ": "boxedText",
    "text": "UV Eigenverbrauch Arlmühle",
    "ds": false
}, {
    "id": "4f08bb6a-42ec-47fb-80c3-f8d5775ff8df",
    "x": 207,
    "y": 389,
    "width": 138,
    "height": 65,
    "typ": "boxedText",
    "text": "Fronius Smart Meter",
    "ds": false
}, {
    "id": "3a81058d-e59a-46fa-8655-e23da4333430",
    "x": 398,
    "y": 211,
    "width": 52,
    "height": 36,
    "typ": "boxedText",
    "text": "LS",
    "ds": false
}, {
    "id": "d81c2d0e-ed83-40c2-8467-19d5d408804f",
    "x": 674,
    "y": 340,
    "width": 52,
    "height": 36,
    "typ": "boxedText",
    "text": "LS",
    "ds": false
}, {
    "id": "1dcc0a70-814f-4c81-89ca-e2fcb8d1ba7f",
    "x": 544,
    "y": 254,
    "width": 65,
    "height": 36,
    "typ": "boxedText",
    "text": "RCDLS",
    "ds": false
}, {
    "id": "add99f6c-63ea-4521-ad73-d552283687fc",
    "x": 1086,
    "y": 251,
    "width": 84,
    "height": 40,
    "typ": "boxedText",
    "text": "Batterie",
    "ds": false
}, {
    "id": "7c3b5811-7539-4b0f-aae1-c31192132481",
    "x": 596,
    "y": 412,
    "width": 155,
    "height": 59,
    "typ": "boxedText",
    "color": "rgb(253 186 116 / 1)",
    "text": "Standort Knoten",
    "ds": false
}, {
    "id": "dde78f12-8d95-4ba7-8fbb-2b041b090d0d",
    "x": 657,
    "y": 497,
    "width": 93,
    "height": 59,
    "typ": "boxedText",
    "text": "Ethernet Switch",
    "ds": false
}, {
    "id": "271b9c26-c156-4346-ae3e-04e1602d4a58",
    "x": 1003,
    "y": 248,
    "width": 63,
    "height": 47,
    "typ": "inverter",
    "ds": false
}, {
    "id": "e824cb46-bf67-44bc-a528-07e43dfaebb5",
    "x": 746,
    "y": 337,
    "width": 63,
    "height": 47,
    "typ": "inverter",
    "ds": false
}, {
    "id": "fd043f46-4fdb-4186-be03-22aaa58e4a40",
    "x": 224,
    "y": 499,
    "width": 93,
    "height": 127,
    "typ": "electricityMeter",
    "text": "Zähler  EV+PV",
    "ds": false
}, {
    "id": "3e2b8740-e3bf-461e-8ad4-86c847b66566",
    "x": 760,
    "y": 44,
    "width": 63,
    "height": 47,
    "typ": "inverter",
    "ds": false
}, {
    "id": "628918ab-93cc-462f-8821-de9e5e298cea",
    "x": 1016,
    "y": 745,
    "width": 63,
    "height": 47,
    "typ": "inverter"
}, {
    "id": "64c92989-5bf3-4ebd-a7fe-644bb1a7ce2f",
    "x": 1112,
    "y": 735,
    "width": 69,
    "height": 66,
    "typ": "circleWithLetter",
    "letter": "G",
    "ds": false
}, {
    "id": "68ad5d55-9e3c-4f80-8683-16ee70e3c984",
    "x": 338,
    "y": 703,
    "width": 93,
    "height": 127,
    "typ": "electricityMeter",
    "text": "Zähler ",
    "ds": false
}, {
    "id": "f7b95977-c09d-4730-ad4b-f198e288c3d5",
    "x": 870,
    "y": 5,
    "width": 69,
    "height": 113,
    "typ": "solarPannel",
    "ds": false
},{
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 968,
    "y": 442,
    "width": 205,
    "height": 72,
    "typ": "cloudIcon",
    "ds": false
}, {
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 963,
    "y": 477,
    "width": 50,
    "height": 70,
    "rotate":"90deg",
    "typ": "dottedLine",
    "ds": false
}]
// translate(963px, 477px)
export default ArlmuehleGraphic;