import React from 'react';
import PropTypes from 'prop-types';


/**
 * This Component Displays a Avatar
 * @example        <UserAvatar firstName={this.props.userAttributes.firstName} surname={this.props.userAttributes.surname} size={"large"} displayStatus={true} status={"online"}/>
 */
class UserAvatar extends React.PureComponent {


    render() {

        let firstLetter = this.props.firstName.charAt(0).toUpperCase()
        let secondLetter = this.props.surname.charAt(0).toUpperCase()

        let letterIndex = (firstLetter.charCodeAt(0) - 65) + (secondLetter.charCodeAt(0) - 65)
        // Avatar background gradient
        let letterColor;
        if (letterIndex < 6) {
            letterColor = "from-green-400 to-blue-500"
        } else if (letterIndex < 12) {
            letterColor = "from-purple-400 via-pink-500 to-red-500"
        } else if (letterIndex < 18) {
            letterColor = "from-purple-400 to-red-200"
        } else if (letterIndex < 24) {
            letterColor = "from-pink-400 to-yellow-300"
        } else if (letterIndex < 30) {
            letterColor = "from-indigo-400 to-green-200"
        } else if (letterIndex < 36) {
            // z.b LT
            letterColor = "from-purple-800 to-green-400"
        } else {
            letterColor = "from-yellow-500 to-green-300"
        }

        // Avatar Size
        let size;
        // Notification Icon Size
        let notificationIconSize;
        if (this.props.size === "small") {
            size = "h-6 w-6"
            notificationIconSize = "h-1.5 w-1.5"

        } else if (this.props.size === "normal") {
            size = "h-8 w-8"
            notificationIconSize = "h-2 w-2"

        } else if (this.props.size === "large") {
            size = "h-10 w-10"
            notificationIconSize = "h-2.5 w-2.5"

        } else if (this.props.size === "extraLarge") {
            size = "h-14 w-14"
            notificationIconSize = "h-3.5 w-3.5"

        }
        // Notification Icon
        let notificationIcon = ""
        if (this.props.displayStatus) {
            if (this.props.status === "unknown") {
                notificationIcon = <span
                    className={"absolute bottom-0 right-0 block " + notificationIconSize + " rounded-full ring-2 ring-white bg-gray-300"}/>
            } else if (this.props.status === "online") {
                notificationIcon = <span
                    className={"absolute bottom-0 right-0 block " + notificationIconSize + " rounded-full ring-2 ring-white bg-green-400"}/>
            } else if (this.props.status === "busy") {
                notificationIcon = <span
                    className={"absolute bottom-0 right-0 block " + notificationIconSize + " rounded-full ring-2 ring-white bg-red-400"}/>
            }
        }


        return (
            <div className="inline-block relative select-none ">
                <span
                    className={"inline-flex items-center justify-center " + size + " rounded-full bg-gradient-to-r " + letterColor}>
  <span className="text-sm font-medium leading-none text-white">{firstLetter}{secondLetter}</span>
</span>
                {notificationIcon}

            </div>)
    };
}

export default UserAvatar;

UserAvatar.propTypes = {
    /**
     * The firstName
     * @default Max
     */
    firstName: PropTypes.string,
    /**
     * The surname
     * @default Mustermann
     */
    surname: PropTypes.string,
    /**
     * The size of the Avatar
     * @example large
     * @default normal
     */
    size: PropTypes.oneOf(['small', 'normal', 'large', 'extraLarge']),
    /**
     * Display status icon
     * @default false
     */
    displayStatus: PropTypes.bool,
    /**
     * The current status state
     * @example busy
     * @default online
     */
    status: PropTypes.oneOf(['unknown', 'online', 'busy']),


}

UserAvatar.defaultProps = {
    firstName: 'Max',
    surname: 'Mustermann',
    size: 'normal',
    status: 'unknown',
    displayStatus: false,

}