import React from 'react';
import PropTypes from 'prop-types';
import TextInput from "./TextInput";

class TextInputFormComponent extends React.Component {

    constructor(props) {
        super(props);


    }



    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return this.state !== nextState || this.props !== nextProps

    }

    render() {
        return (
            <div className="  flex flex-col   " >
                <label className="block text-sm font-medium text-gray-700  h-7 truncate">
                    {this.props.name}
                </label>
                <div className=" rounded-md flex-grow ">
                    <TextInput
                        type={this.props.type ||"text"}
                        value={this.props.value}
                        onChange={event => {
                            if (this.props.onChange !== undefined) {
                                this.props.onChange(event)

                            }
                        }}

                        isReadOnly={this.props.isDisabled}
                        placeholder="---"
                    />

                </div>
            </div>

        )
    };
}

export default TextInputFormComponent;

TextInputFormComponent.propTypes = {
    errorArray: PropTypes.array,
    formSelectionString: PropTypes.string,
    labelWidth: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any.isRequired,
    /**
     * Is input disabled
     * @default false
     *
     */
    isDisabled: PropTypes.bool,
    onConditionChange: PropTypes.func,
    onConditionRemove: PropTypes.func,
}

TextInputFormComponent.defaultProps = {
    formSelectionString: "",
    isDisabled: false,
    labelWidth: 120
}