import React, {Component} from 'react';
import PropTypes from 'prop-types';

class UserManagement extends Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}

UserManagement.propTypes = {};

export default UserManagement;