import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {IoTrashOutline} from "react-icons/io5";

class DeleteButtonComponent extends PureComponent {
    render() {
        return (
            <button type="button" onClick={() => {
                if (this.props.onDelete !== undefined) {
                    this.props.onDelete()
                }
            }}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 group">
                <IoTrashOutline className="-ml-1 mr-3 h-5 w-5 group-hover:text-red-600"/>
                Delete
            </button>
        );
    }
}

DeleteButtonComponent.propTypes = {
    /**
     *
     * The action witch is triggered if the button is clicked
     */
    onDelete: PropTypes.func

};

export default DeleteButtonComponent;