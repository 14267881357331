import React, {Component} from 'react';
import PropTypes from "prop-types";

class ElectricityMeter extends Component {
    render() {
        return (<div
            style={{width: this.props.width, height: this.props.height}}
            className={" border-2 border-gray-200  rounded-md  flex flex-col bg-white "}>
            <div className={"flex justify-center pt-1.5 h-6 border-b-2 border-gray-200"}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                     width="88px" height="18px" viewBox="-0.5 -0.5 88 18" className={"-mt-1"}
                >
                    <defs/>
                    <g>
                        <path d="M 14.37 8 L 71.63 8" fill="none" stroke="#000000" strokeMiterlimit={10}
                              pointerEvents="stroke"/>
                        <path d="M 9.12 8 L 16.12 4.5 L 14.37 8 L 16.12 11.5 Z" fill="#000000" stroke="#000000"
                              strokeMiterlimit={10} pointerEvents="all"/>
                        <path d="M 76.88 8 L 69.88 11.5 L 71.63 8 L 69.88 4.5 Z" fill="#000000" stroke="#000000"
                              strokeMiterlimit={10} pointerEvents="all"/>
                    </g>
                </svg>

            </div>
            <div className={"flex justify-center items-center p-1.5 flex-grow "}><h1
                className={"font-semibold place-content-center break-all"}>{this.props.text}</h1></div>
        </div>)


    }
}


ElectricityMeter.propTypes = {
    scale: PropTypes.string,
    text: PropTypes.string,

};

export default ElectricityMeter;