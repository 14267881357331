const availableDevices = [{
    deviceId: "ereneo-monitoring-node-arlmuehle/CGET340/20",
    deviceName: "Arlmühle 20",
    deviceLocation: "Arlmühle",
    deviceDescription: "Device Id 'ereneo-monitoring-node-arlmuehle/CGET340/20'",
    controlType: "storage"

}, {
    deviceId: "ereneo-monitoring-node-arlmuehle/CGET340/21",
    deviceName: "Arlmühle 21",
    deviceLocation: "Arlmühle",
    deviceDescription: "Device Id 'ereneo-monitoring-node-arlmuehle/CGET340/21'",
    controlType: "consumer"

}, {
    deviceId: "ereneo-monitoring-node-arlmuehle/CGET340/22",
    deviceName: "Arlmühle 22",
    deviceLocation: "Arlmühle",
    deviceDescription: "Device Id 'ereneo-monitoring-node-arlmuehle/CGET340/22'",
    controlType: "generator"

}, {
    deviceId: "ereneo-monitoring-node-arlmuehle/CGET340/23",
    deviceName: "Arlmühle 23",
    deviceLocation: "Arlmühle",
    deviceDescription: "Device Id 'ereneo-monitoring-node-arlmuehle/CGET340/23'",
    controlType: "generator"
}, {
    deviceId: "ereneo-monitoring-node-arlmuehle/CGET340/24",
    deviceName: "Arlmühle 24",
    deviceLocation: "Arlmühle",
    deviceDescription: "Device Id 'ereneo-monitoring-node-arlmuehle/CGET340/24'",
    controlType: "none"
}, {
    deviceId: "ereneo-monitoring-node-arlmuehle/VCC/1",
    deviceName: "Arlmühle Speicher",
    deviceLocation: "Arlmühle",
    deviceDescription: "Device Id 'ereneo-monitoring-node-arlmuehle/VCC/1'",
}, {
    deviceId: "ereneo-monitoring-node-betriebszentrale/CGET340/25",
    deviceName: "Betriebszentrale 25",
    deviceLocation: "Betriebszentrale",
    deviceDescription: "Device Id 'ereneo-monitoring-node-betriebszentrale/CGET340/25'",
    controlType: "storage"
}, {
    deviceId: "ereneo-monitoring-node-betriebszentrale/CGET340/26",
    deviceName: "Betriebszentrale 26",
    deviceLocation: "Betriebszentrale",
    deviceDescription: "Device Id 'ereneo-monitoring-node-betriebszentrale/CGET340/26'",
    controlType: "generator"

}, {
    deviceId: "ereneo-monitoring-node-betriebszentrale/CGET340/27",
    deviceName: "Betriebszentrale 27",
    deviceLocation: "Betriebszentrale",
    deviceDescription: "Device Id 'ereneo-monitoring-node-betriebszentrale/CGET340/27'",
    controlType: "consumer"

}, {
    deviceId: "ereneo-monitoring-node-betriebszentrale/CGET340/29",
    deviceName: "Betriebszentrale 29",
    deviceLocation: "Betriebszentrale",
    deviceDescription: "Device Id 'ereneo-monitoring-node-betriebszentrale/CGET340/29'",
    controlType: "consumer"
}, {
    deviceId: "ereneo-monitoring-node-betriebszentrale/VCC/1",
    deviceName: "Betriebszentrale Speicher",
    deviceLocation: "Betriebszentrale",
    deviceDescription: "Device Id 'ereneo-monitoring-node-betriebszentrale/VCC/1'",
}, {
    deviceId: "ereneo-monitoring-node-kundencenter/CGET340/28",
    deviceName: "Kundencenter 28",
    deviceLocation: "Kundencenter",
    deviceDescription: "Device Id 'ereneo-monitoring-node-kundencenter/CGET340/28'",
    controlType: "consumer"

}]
export default availableDevices;