import React, {Component} from 'react';
import MeasuringPoint from "../circuitSymbols/MeasuringPoint";
import {Rnd} from "react-rnd";
import ArlmuehleBackground from "../circuitSymbols/ArlmuehleBackground";
import Group from "../circuitSymbols/Group";
import {v4 as uuid} from "uuid";
import BoxedText from "../circuitSymbols/BoxedText";
import Inverter from "../circuitSymbols/Inverter";
import ElectricityMeter from "../circuitSymbols/ElectricityMeter";
import DisplayValue from "./DisplayValue";
import {PubSub} from "@aws-amplify/pubsub";
import SolarPannel from "../circuitSymbols/SolarPannel";
import TextInput from "../ERPImport/TextInput";
import TextInputFormComponent from "../ERPImport/TextInputFormComponent";
import DeleteButtonComponent from "../ERPImport/DeleteButtonComponent";
import objectPath from "object-path";
import JustText from "../circuitSymbols/JustText";
import ValueText from "../circuitSymbols/ValueText";
import CircleWithLetter from "../circuitSymbols/circleWithLetter";
import CloudIcon from "../circuitSymbols/CloudIcon";
import DottedLine from "../circuitSymbols/dottedLine";

const isEditMode = false

class InteractiveGraphic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: uuid(),
            elements: props.graphicElements,
            selectedItemId: undefined

        }
    }

    componentDidMount() {


    }

    updateValue(itemIndex, key, value) {
        this.setState((state) => {
            let old = state.elements
            old[itemIndex][key] = value
            return ({elements: old})
        })
    }

    displaySettings() {
        const index = this.state.elements.findIndex(item => (item.id === this.state.selectedItemId))
        if (index !== undefined) {
            let thisElement = this.state.elements[index]

            if (thisElement !== undefined) {


                switch (thisElement.typ) {
                    case "measuringPoint":
                        return (<div>
                            type: measuringPoint
                            <TextInputFormComponent name={"label:"}
                                                    value={thisElement.text}
                                                    onChange={event => {
                                                        this.updateValue(index, "text", event)
                                                    }}
                            />
                            <TextInputFormComponent name={"value:"}
                                                    value={thisElement.value}
                                                    onChange={event => {
                                                        this.updateValue(index, "value", event)
                                                    }}
                            />
                            <TextInputFormComponent name={"link:"}
                                                    value={thisElement.link}
                                                    onChange={event => {
                                                        this.updateValue(index, "link", event)
                                                    }}
                            />

                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "valueText":
                        return (<div>
                            type: valueText

                            <TextInputFormComponent name={"value:"}
                                                    value={thisElement.value}
                                                    onChange={event => {
                                                        this.updateValue(index, "value", event)
                                                    }}
                            />


                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "group":
                        return (<div>
                            type: Group

                            <TextInputFormComponent name={"label:"}
                                                    value={thisElement.text}
                                                    onChange={event => {
                                                        this.updateValue(index, "text", event)
                                                    }}
                            />
                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "boxedText":
                        return (<div>
                            type: boxedText

                            <TextInputFormComponent name={"label:"}
                                                    value={thisElement.text}
                                                    onChange={event => {
                                                        this.updateValue(index, "text", event)
                                                    }}
                            />
                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "justText":
                        return (<div>
                            type: boxedText

                            <TextInputFormComponent name={"label:"}
                                                    value={thisElement.text}
                                                    onChange={event => {
                                                        this.updateValue(index, "text", event)
                                                    }}
                            />
                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "inverter":
                        return (<div>
                            type: inverter
                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "electricityMeter":
                        return (<div>
                            type: electricityMeter

                            <TextInputFormComponent name={"label:"}
                                                    value={thisElement.text}
                                                    onChange={event => {
                                                        this.updateValue(index, "text", event)
                                                    }}
                            />
                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "circleWithLetter":
                        return (<div>
                            type: circleWithLetter

                            <TextInputFormComponent name={"letter:"}
                                                    value={thisElement.letter}
                                                    onChange={event => {
                                                        this.updateValue(index, "letter", event)
                                                    }}
                            />
                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>
                        </div>)
                    case "solarPannel":
                        return (<div>
                            type: solarPannel
                            <DeleteButtonComponent onDelete={() => {
                                this.setState((state) => {
                                    return ({
                                        elements: state.elements.filter(function (element) {
                                            return element.id !== thisElement.id
                                        }), selectedItemId: ""
                                    })
                                })
                            }}/>

                        </div>)


                }
            }
        }


    }


    displayTyp(element) {
        switch (element.typ) {
            case "measuringPoint":
                return (<MeasuringPoint scale={"0.5"} text={element.text} link={element.link}
                                        value={element.value || "000"}/>)
            case "valueText":
                return (<ValueText
                    value={element.value || "000"}/>)
            case "group":
                return (<Group width={element.width} backgroundColor={element.color}
                               height={element.height}
                               text={element.text}/>)
            case "boxedText":
                return (<BoxedText width={element.width} backgroundColor={element.color || "#00000"}
                                   height={element.height}
                                   text={element.text}/>)

            case "justText":
                return (<JustText width={element.width} backgroundColor={element.color || "#00000"}
                                  height={element.height}
                                  text={element.text}/>)
            case "inverter":
                return (<Inverter width={element.width}
                                  height={element.height}
                />)
            case "electricityMeter":
                return (<ElectricityMeter width={element.width} text={element.text}
                                          height={element.height}
                />)
            case "solarPannel":
                return (<SolarPannel width={element.width}
                                     height={element.height}
                />)
            case "circleWithLetter":
                return (<CircleWithLetter width={element.width}
                                          height={element.height}
                                          letter={element.letter}
                />)
            case "cloudIcon":
                return (<CloudIcon width={element.width}
                                   height={element.height}

                />)

            case "dottedLine":
                return (<DottedLine width={element.width} rotate={element.rotate || '0deg'}
                                   height={element.height}

                />)
        }


    }


    render() {
        return (<>

                <div
                    style={{
                        width: "1250px",
                        height: "850px",
                        position: "relative",
                        display: "block",
                    }} id={"viewport"}
                    className={"transition-transform ease-in-out duration-300 subpixel-antialiased  overflow-hidden "}>
                    <div className={"absolute pointer-events-none"} style={{transform: "translate(20px, -130px)"}}>
                        {this.props.background}
                    </div>
                    {(!isEditMode) ? (<>
                        {this.props.graphicElements.map(element => {
                            return (<div className={"absolute opacity-100"}
                                         style={{transform: "translate(" + element.x + "px, " + element.y + "px)"}}>{this.displayTyp(element)}  </div>
                            )
                        })}

                    </>) : (<>
                        {this.state.elements.map((element, index) => {


                            return (
                                <Rnd
                                    size={{width: element.width, height: element.height}}
                                    position={{x: element.x, y: element.y}}
                                    resizeHandleClasses={""}
                                    resizeHandleWrapperClass={""}
                                    resizeHandleStyles={{
                                        bottom: {
                                            position: 'absolute',
                                            userSelect: 'none',

                                            cursor: 'row-resize',
                                            transformOrigin: 'center',
                                            transform: 'scale(1, ' + (1) + ')'
                                        },
                                        right: {
                                            position: 'absolute',
                                            userSelect: 'none',

                                            top: '0px',
                                            cursor: 'col-resize',
                                            transform: 'scale( ' + (1) + ',1)'
                                        },
                                        bottomRight: {
                                            position: 'absolute',
                                            userSelect: 'none',

                                            right: '-9px',
                                            bottom: '-9px',
                                            cursor: 'se-resize',
                                            transform: 'scale( ' + (1) + ')'
                                        }
                                    }}
                                    enableResizing={{
                                        bottom: true,
                                        bottomLeft: false,
                                        bottomRight: true,
                                        left: false,
                                        right: true,
                                        top: false,
                                        topLeft: false,
                                        topRight: false,
                                    }}
                                    cancel=".cancel"
                                    style={{
                                        position: "absolute",
                                        willChange: "transform"
                                    }}
                                    bounds={"parent"}
                                    id={element.id}
                                    key={index}
                                    resizeHandleComponent={{
                                        bottomRight: <svg width={"100%"} height={"100%"}
                                                          style={{

                                                              width: '16px',
                                                              height: '16px',
                                                              border: "2px black",
                                                              zIndex: 99999
                                                          }}>
                                            <rect width="100%" height="100%" rx="5px" ry="5px"
                                                  fill="white"
                                                  stroke="#808080" strokeWidth={2}
                                                  strokeLinejoin="round"/>
                                        </svg>,
                                        bottom: <svg width={"100%"} height={"100%"} style={{
                                            width: '100%',
                                            height: '1px',
                                        }}>
                                            <rect width={"100%"} height={"100%"}
                                                  style={{fill: 'rgba(2,2,0,0)'}}/>
                                        </svg>,
                                        right: <svg width={"100%"} height={"100%"} style={{
                                            width: '1px',
                                            height: 'calc(100% - ' + (5 * (1)) + 'px)',
                                        }}>
                                            <rect width={"100%"} height={"100%"}
                                                  style={{fill: 'rgba(2,2,0,0)'}}/>
                                        </svg>,
                                    }}
                                    onResizeStop={(e, direction, ref, delta, position) => {
                                        this.setState((state) => {
                                            let elements = state.elements
                                            elements[index].width = parseInt(ref.style.width.replace("px", ""))
                                            elements[index].height = parseInt(ref.style.height.replace("px", ""))
                                            return ({elements})
                                        })
                                        navigator.clipboard.writeText(JSON.stringify(this.state.elements.map(item => ({
                                            ...item,
                                            id: uuid()
                                        })))).then(function () {
                                            console.log('Async: Copying to clipboard was successful!');
                                        }, function (err) {
                                            console.error('Async: Could not copy text: ', err);
                                        });
                                    }}
                                    tabIndex={10 + index}
                                    disableDragging={element.ds}
                                    onMouseDown={() => {
                                        console.log(element);
                                        this.setState({selectedItemId: element.id})

                                    }}
                                    onDragStop={(e, d) => {
                                        this.setState((state) => {
                                            let elements = state.elements
                                            elements[index].x = d.x
                                            elements[index].y = d.y
                                            return ({elements})
                                        })
                                        navigator.clipboard.writeText(JSON.stringify(this.state.elements.map(item => ({
                                            ...item,
                                            id: uuid()
                                        })))).then(function () {
                                            console.log('Async: Copying to clipboard was successful!');
                                        }, function (err) {
                                            console.error('Async: Could not copy text: ', err);
                                        });
                                    }}

                                >
                                    <div className={"absolute  top-0 left-0 "}>

                                        <div style={{
                                            width: element.width,
                                            height: element.height,
                                            overflow: 'hidden'
                                        }}
                                             className={"  relative "}>
                                            {/*top*/}
                                            <svg width={"100%"} height={"100%"}
                                                 className={"absolute   top-0 left-0 "}>
                                                <rect width={"100%"} height={"1px"} x={0} y={0}
                                                      style={{
                                                          transform: 'scale(1, ' + (1) + ')',
                                                          fill: 'gray',
                                                      }}/>

                                            </svg>
                                            {/*bottom*/}
                                            <svg className={"absolute   top-0 left-0 transform rotate-180 "}
                                                 width={"100%"} height={"100%"}>
                                                <rect width={"100%"} height={"1px"} x={0} y={0}
                                                      style={{
                                                          transform: 'scale(1, ' + (1) + ')',
                                                          fill: 'gray',
                                                      }}/>

                                            </svg>
                                            {/*left*/}
                                            <svg className={"absolute    top-0 left-0   "} width={"100%"}
                                                 height={"100%"}>
                                                <rect width={"1px"} height={"100%"} x={0} y={0}
                                                      style={{
                                                          transform: 'scale( ' + (1) + ',1)',
                                                          fill: 'gray',
                                                      }}/>

                                            </svg>

                                            {/*right*/}
                                            <svg className={"absolute    top-0 left-0 transform rotate-180   "}
                                                 width={"100%"} height={"100%"}>
                                                <rect width={"1px"} height={"100%"} x={0} y={0}
                                                      style={{
                                                          transform: 'scale( ' + (1) + ',1)',
                                                          fill: 'gray',
                                                      }}/>

                                            </svg>

                                        </div>

                                    </div>
                                    {this.displayTyp(element)}

                                </Rnd>
                            )
                        })}


                        <div className={"space-x-2 h-11 inline-flex  align-middle p-2 shadow "}>
                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 30,
                                        height: 30,
                                        typ: "measuringPoint",
                                        text: "23",
                                        value: "{ereneo_monitoring_node_kundencenter.CGET340.23.kWL2} kW",
                                        link: "/device/arlmuehle",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new measuringPoint
                            </button>
                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 30,
                                        height: 30,
                                        typ: "valueText",
                                        text: "23",
                                        value: "{ereneo_monitoring_node_kundencenter.CGET340.23.kWL2} kW",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new valueText
                            </button>
                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 200,
                                        height: 200,
                                        typ: "group",
                                        color: "rgb(209 213 219 / 0.3)",
                                        text: "Schaltkasten \"Standortknoten Arlmühle\"",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new Group
                            </button>

                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 138,
                                        height: 110,
                                        typ: "boxedText",
                                        text: "UV Eigenverbrauch Arlmühle",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new boxedText
                            </button>

                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 63,
                                        height: 47,
                                        typ: "inverter",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new inverter
                            </button>
                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 93,
                                        height: 127,
                                        typ: "electricityMeter",
                                        text: "Zähler  EV+PV",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new electricityMeter
                            </button>
                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 93,
                                        height: 127,
                                        typ: "solarPannel",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new solarPannel
                            </button>

                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 93,
                                        height: 127,
                                        typ: "justText",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new justText
                            </button>

                            <button type="button" onClick={() => {
                                this.setState((state) => {
                                    let old = state.elements
                                    old = [...old, {
                                        id: uuid(),
                                        x: 100,
                                        y: 100,
                                        width: 93,
                                        height: 127,
                                        typ: "circleWithLetter",
                                        letter: "G",
                                        ds: false
                                    }]
                                    return ({elements: old})
                                })
                            }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Add new circleWithLetter
                            </button>


                        </div>

                        {this.state.selectedItemId !== undefined && (<div className={""} style={{minHeight: "300px"}}
                        >
                            <h1> Settings</h1>

                            {this.displaySettings()}


                        </div>)}
                    </>)}

                </div>


            </>
        );
    }
}

InteractiveGraphic.propTypes = {};

export default InteractiveGraphic;