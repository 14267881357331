import React, {Component} from 'react';
import PageMenu from "./PageMenu";
import logo from "../images/stadtwerke_pfaffenhofen_logo.png";
import UserInfo from "./UserInfo";
import UserAvatar from "../ERPImport/UserAvatar";

class PageStructure extends Component {

    render() {

        return (
            <div>
                <div className="fixed inset-0 flex z-40 md:hidden" role="dialog" aria-modal="true">

                    <div className="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true"/>

                    <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">

                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                            <button type="button"
                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Close sidebar</span>
                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img className="h-8 w-auto"
                                     src={logo}
                                     alt="Logo"/>
                            </div>

                            <PageMenu/>
                        </div>
                        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                            <UserInfo/>
                        </div>
                    </div>
                    <div className="flex-shrink-0 w-14">
                        {/* Force sidebar to shrink to fit close icon */}
                    </div>
                </div>
                {/* Static sidebar for desktop */}
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
                        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                            <div className="flex items-center flex-shrink-0 px-4">

                                <img className="h-12 w-auto"
                                     src={logo}
                                     alt="Logo"/>
                            </div>
                            <PageMenu/>
                        </div>
                        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                            <UserInfo/>
                        </div>
                    </div>
                </div>
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                        <button type="button"
                                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open sidebar</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        </button>
                    </div>
                    <main className="flex-1">
                        {this.props.children}

                    </main>
                </div>
            </div>
        );
    }
}

PageStructure.propTypes = {};

export default PageStructure