import DisplayValue from "../components/DisplayValue";
import React from "react";
import PageTitle from "../components/PageTitle";

const BetriebszentraleGraphic = [{
    "id": "13178e70-dee1-4a67-b2f3-a1d40d96e6e4",
    "x": 439,
    "y": 14,
    "width": 510,
    "height": 448,
    "typ": "group",
    "color": "rgb(209 213 219 / 0.3)",
    "text": "Schaltkasten \"Standortknoten Betriebszentrale\"",
    "ds": false
}, {
    "id": "cd66e9df-dea4-46bd-91cc-b669231f6a2a",
    "x": 957,
    "y": 16,
    "width": 234,
    "height": 151,
    "typ": "group",
    "color": "rgb(2 132 199 / 0.3)",
    "text": "ereneo storage",
    "ds": false
}, {
    "id": "308afb72-5adb-44af-8bd9-28fe94f736fa",
    "x": 141,
    "y": 272,
    "width": 149,
    "height": 68,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-betriebszentrale.CGET340.26.kWL1} + {ereneo-monitoring-node-betriebszentrale.CGET340.26.kWL2} +{ereneo-monitoring-node-betriebszentrale.CGET340.26.kWL3}",
    "link": "/device/ereneo-monitoring-node-betriebszentrale/CGET340/26",
    "text": "26",
    "ds": false
}, {
    "id": "914bf012-1d09-4449-8865-94abafd517c3",
    "x": 632,
    "y": 81,
    "width": 30,
    "height": 30,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-betriebszentrale.CGET340.25.kWL1} + {ereneo-monitoring-node-betriebszentrale.CGET340.25.kWL2} +{ereneo-monitoring-node-betriebszentrale.CGET340.25.kWL3}",
    "link": "/device/ereneo-monitoring-node-betriebszentrale/CGET340/25",
    "text": "25",
    "ds": false
}, {

    "id": "be88ce3a-5277-4ccf-8665-56ce4c5e53c5",
    "x": 812,
    "y": 654,
    "width": 117,
    "height": 57,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-betriebszentrale.CGET340.29.kWL1} + {ereneo-monitoring-node-betriebszentrale.CGET340.29.kWL2} +{ereneo-monitoring-node-betriebszentrale.CGET340.29.kWL3}",
    "link": "/device/ereneo-monitoring-node-betriebszentrale/CGET340/29",
    "text": "29",
    "ds": false
}, {
    "id": "8748545b-dd1a-449c-8fc8-ad064d99c1e4",
    "x": 809,
    "y": 553,
    "width": 198,
    "height": 67,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-betriebszentrale.CGET340.27.kWL1} + {ereneo-monitoring-node-betriebszentrale.CGET340.27.kWL2} +{ereneo-monitoring-node-betriebszentrale.CGET340.27.kWL3}",
    "link": "/device/ereneo-monitoring-node-betriebszentrale/CGET340/27",
    "text": "27",
    "ds": false
}, {
    "id": "b51184d8-f35f-41dc-96bf-14d2a5e25a49",
    "x": 1022,
    "y": 544,
    "width": 197,
    "height": 71,
    "typ": "boxedText",
    "text": "UV Betriebszentrale 3",
    "ds": false
}, {
    "id": "76cd8818-129b-44ec-bb47-18fe3b81aea3",
    "x": 166,
    "y": 582,
    "width": 113,
    "height": 42,
    "typ": "boxedText",
    "text": "NA Schutz",
    "ds": false
}, {
    "id": "ed157a35-4acb-4b65-9451-1916954b03ff",
    "x": 567,
    "y": 237,
    "width": 52,
    "height": 36,
    "typ": "boxedText",
    "text": "LS",
    "ds": false
}, {
    "id": "da79047d-f021-4de5-a382-253ece8af889",
    "x": 464,
    "y": 235,
    "width": 65,
    "height": 36,
    "typ": "boxedText",
    "text": "RCDLS",
    "ds": false
}, {
    "id": "32caa0c3-7f28-4b50-9c98-3758bc808505",
    "x": 1067,
    "y": 91,
    "width": 84,
    "height": 40,
    "typ": "boxedText",
    "text": "Batterie",
    "ds": false
}, {
    "id": "8d5639e5-014e-47e2-ac1b-10dba02af8b8",
    "x": 758,
    "y": 230,
    "width": 155,
    "height": 59,
    "typ": "boxedText",
    "color": "rgb(253 186 116 / 1)",
    "text": "Standort Knoten",
    "ds": false
}, {
    "id": "8c442943-e944-450c-92fa-f88e249353ce",
    "x": 498,
    "y": 368,
    "width": 93,
    "height": 59,
    "typ": "boxedText",
    "text": "Ethernet Switch",
    "ds": false
}, {
    "id": "b73cbf75-3d3d-48fb-ab16-6741cf6d9f46",
    "x": 988,
    "y": 85,
    "width": 63,
    "height": 47,
    "typ": "inverter",
    "ds": false
}, {
    "id": "6973aca1-edc4-4a67-9126-fcfa5cb3a837",
    "x": 653,
    "y": 232,
    "width": 63,
    "height": 47,
    "typ": "inverter",
    "ds": false
}, {
    "id": "6949a47f-8739-432a-9c01-a3edf6c42d72",
    "x": 168,
    "y": 392,
    "width": 93,
    "height": 127,
    "typ": "electricityMeter",
    "text": "Zähler  PV",
    "ds": false
}, {
    "id": "48cd0c56-939d-4b82-aba6-4afbfa836769",
    "x": 180,
    "y": 173,
    "width": 63,
    "height": 47,
    "typ": "inverter",
    "ds": false
}, {
    "id": "caf89e28-95d0-4521-b988-158ad680bdd1",
    "x": 32,
    "y": 175,
    "width": 63,
    "height": 47,
    "typ": "inverter"
}, {
    "id": "49796849-28df-438b-9a26-657ee3aa26b6",
    "x": 1025,
    "y": 642,
    "width": 197,
    "height": 67,
    "typ": "boxedText",
    "text": "UV Betriebszentrale 2",
    "ds": false
}, {
    "id": "7e6c94a8-a9fc-4391-b2a1-179d1448119a",
    "x": 63,
    "y": 625,
    "width": 96,
    "height": 127,
    "typ": "electricityMeter",
    "text": "Zähler",
    "ds": false
}, {
    "id": "23d9e23d-4424-4e71-bba7-f417c5faebac",
    "x": 1022,
    "y": 752,
    "width": 205,
    "height": 58,
    "typ": "boxedText",
    "text": "UV Betriebszentrale 1",
    "ds": false
}, {
    "id": "3df7cdde-61ec-4d44-8fb8-8d62b3284e94",
    "x": 179,
    "y": 27,
    "width": 72,
    "height": 127,
    "typ": "solarPannel",
    "ds": false
}, {
    "id": "dc8333ed-a6d6-40ac-a301-ce26c8a8de67",
    "x": 38,
    "y": 26,
    "width": 73,
    "height": 127,
    "typ": "solarPannel",
    "ds": false
}, {
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 578,
    "y": 493,
    "width": 205,
    "height": 72,
    "typ": "cloudIcon",
    "ds": false
}, {
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 676,
    "y": 455,
    "width": 5,
    "height": 50,
    "typ": "dottedLine",
    "ds": false
}] // translate(578px, 493px)
export default BetriebszentraleGraphic;