
const availableClients = [{
    clientId: "ereneo-monitoring-node-kundencenter",
    clientName: "ereneo monitoring node kundencenter",
    clientLocation: "Kundencenter",
    clientDescription: "Device Id "

}, {
   clientId: "ereneo-monitoring-node-arlmuehle",
   clientName: "ereneo monitoring node arlmuehle ",
   clientLocation: "Arlmühle",
   clientDescription: "Device Id "

}, {
    clientId: "ereneo-monitoring-node-betriebszentrale",
    clientName: "ereneo monitoring node betriebszentrale",
    clientLocation: "Betriebszentrale",
    clientDescription: "Device Id "

}, ]
export default availableClients;