import DisplayValue from "../components/DisplayValue";
import React from "react";
import PageTitle from "../components/PageTitle";

const KundencenterGraphic = [{
    "id": "2d38f8dc-e5d6-418d-99a6-327019c8fa1f",
    "x": 465,
    "y": 50,
    "width": 510,
    "height": 414,
    "typ": "group",
    "color": "rgb(209 213 219 / 0.3)",
    "text": "Schaltkasten \"Standortknoten Kundencenter\"",
    "ds": false
}, {
    "id": "3d1eec60-b7ee-45ff-a7d7-4da70adbaa38",
    "x": 222,
    "y": 601,
    "width": 149,
    "height": 68,
    "typ": "measuringPoint",
    "value": "{ereneo-monitoring-node-kundencenter.CGET340.28.kWL1} + {ereneo-monitoring-node-kundencenter.CGET340.28.kWL2} +{ereneo-monitoring-node-kundencenter.CGET340.28.kWL3}",
    "link": "/device/ereneo-monitoring-node-kundencenter/CGET340/28",
    "text": "28",
    "ds": false
},  {
    "id": "26cc0038-52e7-4f9a-871f-7973bf10c85a",
    "x": 502,
    "y": 190,
    "width": 65,
    "height": 36,
    "typ": "boxedText",
    "text": "RCDLS",
    "ds": false
}, {
    "id": "79e5db4c-de66-48c4-9413-ad42c28f4f52",
    "x": 792,
    "y": 180,
    "width": 155,
    "height": 59,
    "typ": "boxedText",
    "color": "rgb(253 186 116 / 1)",
    "text": "Standort Knoten",
    "ds": false
}, {
    "id": "73332a2c-4d64-4f7c-8c2f-e6c6122e4397",
    "x": 534,
    "y": 320,
    "width": 93,
    "height": 59,
    "typ": "boxedText",
    "text": "Ethernet Switch",
    "ds": false
}, {
    "id": "03c03f66-0a4b-42bf-acee-e15b39cce0a3",
    "x": 711,
    "y": 184,
    "width": 63,
    "height": 47,
    "typ": "inverter",
    "ds": false
}, {
    "id": "ce8103fa-db17-49cc-8c5c-8df2f7830323",
    "x": 73,
    "y": 573,
    "width": 96,
    "height": 127,
    "typ": "electricityMeter",
    "text": "Zähler ",
    "ds": false
}, {
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 970,
    "y": 598,
    "width": 205,
    "height": 72,
    "typ": "boxedText",
    "text": "Hausverteiler Kundencenter",
    "ds": false
}, {
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 639,
    "y": 212,
    "width": 205,
    "height": 72,
    "typ": "justText",
    "text": "5 VOC",
    "ds": false
}, {
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 625,
    "y": 493,
    "width": 205,
    "height": 72,
    "typ": "cloudIcon",
    "ds": false
}, {
    "id": "6c663c26-9e8e-4df9-b55c-2005ed433083",
    "x": 726,
    "y": 455,
    "width": 5,
    "height": 50,
    "typ": "dottedLine",
    "ds": false
}]
// translate(726px, 455px)
export default KundencenterGraphic;