import './App.css';
import PageStructure from "./components/PageStructure";
import * as React from "react";

import {BrowserRouter, Route, Routes, useParams} from "react-router-dom";
import Startpage from "./pages/Startpage";
import Console from "./pages/Console";
import UserManagement from "./pages/UserManagement";
import Settings from "./pages/Settings";

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {withAuthenticator} from "@aws-amplify/ui-react";
import {AWSIoTProvider} from "@aws-amplify/pubsub";
import RowData from "./pages/RowData";
import Device from "./pages/Device";
import {IotCore} from "./iotCore";
import ControlFlow from "./pages/ControlFlow";
import ArlmuehlePage from "./DevicePages/ArlmuehlePage";
import KundencenterPage from "./DevicePages/KundencenterPage";
import BetriebszentralePage from "./DevicePages/BetriebszentralePage";
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsconfig);
const DeviceProxy = (props) => {
    let {installationId, deviceType, deviceId} = useParams();
    return (
        <>
            <Device installationId={installationId} deviceType={deviceType} deviceId={deviceId}/>
        </>
    );
};

class App extends React.Component {

    componentDidMount() {
        IotCore.init()

    }


    render() {

        return (
            <BrowserRouter>
                <PageStructure>

                    <Routes>


                        <Route path="/" element={<Startpage/>}>

                        </Route>
                        <Route path="/console" element={<Console/>}>

                        </Route>
                        <Route path="/data" element={<RowData/>}>

                        </Route>

                        <Route path="/users" element={<UserManagement/>}>

                        </Route>
                        <Route path="/settings" element={<Settings/>}>

                        </Route>

                        <Route path="/editor" element={<Settings/>}>

                        </Route>
                        <Route path="/controlFlow" element={<ControlFlow/>}>

                        </Route>
                        <Route path="/device/:installationId/:deviceType/:deviceId/" element={<DeviceProxy/>}>
                        </Route>

                        <Route path="/device" >
                            <Route path="ereneo-monitoring-node-arlmuehle" element={<ArlmuehlePage/>}/>
                            <Route path="ereneo-monitoring-node-kundencenter" element={<KundencenterPage/>}/>
                            <Route path="ereneo-monitoring-node-betriebszentrale" element={<BetriebszentralePage/>}/>

                        </Route>

                    </Routes>
                </PageStructure>
            </BrowserRouter>

        );
    }
}


export default withAuthenticator(App);
