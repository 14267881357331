import React, {Component} from 'react';
import PropTypes from 'prop-types';

class JustText extends Component {
    render() {
        return (
            <div style={{width: this.props.width, height: this.props.height,backgroundColor:this.props.backgroundColor}} className={"flex flex-row items-center justify-center "}>
                <div className={"font-medium"}> {this.props.text} </div>
            </div>
        );
    }
}

JustText.propTypes = {
    text: PropTypes.string,
    width:PropTypes.string,
    height: PropTypes.string,
    backgroundColor: PropTypes.string,

};

export default JustText;