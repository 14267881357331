import React, {Component} from 'react';
import PropTypes from 'prop-types';

class DottedLine extends Component {
    render() {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                     width={this.props.width} height={this.props.height}
                     style={{transform: 'rotate(' + this.props.rotate + ')'}}
                     content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-06-29T07:05:15.058Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;C9DeXw8mtb0z3YvEDcsi&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;HhlOcK0HFCIa65MX1F1e&quot; name=&quot;Page-1&quot;>jZPbboMwDIafhstJQOhaLnvcpGlSpUqbtLuMuIAW4i6YlvbpFyCcxqbtBsVf7N/xAYets/JB81PyjAKk47uidNjG8f0g8M23AtcGsDBsQKxT0SCvB4f0Bha6lhapgHzkSIiS0tMYRqgURDRiXGu8jN2OKMdZTzyGCThEXE7payooaejCn/f8EdI4aTN797a+jLfOtpI84QIvA8S2DltrRGpOWbkGWfWu7UsTt/vltnuYBkX/CSjPtBOJgoP7Ht4Wt6fPtxd9Z1XOXBa2YPtYurYdACWWVSONpVAZuBI8T6BS9YyRUCbtseJ7TgRa1cR3maE5afzommfKXmkslKgFXGMdUdEaJeo6HdttVst50MX9dNM8EcRkcn0rvK7BZjEBMyB9NS6XfoQzO5ZkML2WaZCc0vNYnttNiju5LsMeU5PYd9ulb3Xszs9CdyyRY6EjsFHDkf0hFLBvQsR1DDQRModB2T2qN6I1+81r3Pvfl22/AA==</diagram></mxfile>">
                    <defs/>
                    <g>
                        <path d="M 1 161 L 1 1" fill="none" stroke={"#fdba74"} strokeWidth={5} strokeMiterlimit={10}
                              strokeDasharray="2 6" pointerEvents="stroke"/>
                    </g>
                </svg>

            </>
        );
    }
}

DottedLine.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};

export default DottedLine;