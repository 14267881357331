import React, {Component} from 'react';
import {Auth} from "aws-amplify";
import UserAvatar from "../ERPImport/UserAvatar";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userAttributes:{}
        }
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {

            this.setState({userAttributes: {...user.attributes}})


        }).catch(err => console.log(err));
    }


    render() {
        return (
            <a href="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                    <div>
                        <UserAvatar firstName={this.state.userAttributes.email}
                                    surname={""} />
                    </div>
                    <div className="ml-3 truncate">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 ">
                            {this.state.userAttributes.email}
                        </p>

                    </div>
                </div>
            </a>
        );
    }
}

UserInfo.propTypes = {};

export default UserInfo;