import Amplify, {Auth} from "aws-amplify";
import {AWSIoTProvider, PubSub} from "@aws-amplify/pubsub";
import {createStore} from "redux";
import {Database} from "./APIClient";
import availableDevices from "./Devices";
import availableClients from "./Clients";

const store = createStore((state = 0, action) => {

    return action

})
const valueStore = createStore((state = {}, action) => {

    switch (action.type) {
        case "update":
            if (!state.hasOwnProperty(action.clientId)) {
                state[action.clientId] = {}
            }

            if (!state[action.clientId].hasOwnProperty(action.component)) {
                state[action.clientId][action.component] = {}
            }

            state[action.clientId][action.component][action.deviceId] = {...state[action.clientId][action.component][action.deviceId], ...action.data}

    }


    return state

})

let iotCoreSub;

const IotCore = function () {
    function init() {

        Amplify.addPluggable(new AWSIoTProvider({
            aws_pubsub_region: 'eu-central-1',
            aws_pubsub_endpoint: 'wss://amwb6lu2ntvj4-ats.iot.eu-central-1.amazonaws.com/mqtt',

        }));
        let allAvailableClients = availableClients.map(item => item.clientId).filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
        }).map(item => 'installation/' + item)
        Auth.currentCredentials().then((info) => {
            PubSub.subscribe(allAvailableClients).subscribe({
                next: data => {
                    store.dispatch({...data.value, type: data.value.typ})
                },
                error: error => {
                    console.log('error');
                    console.error(error);
                },
                close: () => console.log('Done'),
            });
        })
        store.subscribe(() => {
            let state = store.getState()
            if (state.type === "data") {
                if (state.hasOwnProperty("dataCollection")) {
                    Object.keys(state.dataCollection).map(deviceId => {
                        let data = {...state.dataCollection[deviceId], timestamp: state.timestamp}
                        valueStore.dispatch({
                            type: "update",
                            data,
                            deviceId,
                            clientId: state.clientId,
                            component: state.component
                        })

                    })
                } else if (state.hasOwnProperty("data")) {
                    let data = {...state.data, timestamp: state.timestamp}
                    valueStore.dispatch({
                        type: "update",
                        data,
                        deviceId: 1,
                        clientId: state.clientId,
                        component: state.component
                    })
                }
            }
        })
    }

    function remove() {
        iotCoreSub.unsubscribe()
    }

    function loadFromDatabase(clientId, component, deviceId, name) {
        Database.getGraph(clientId + "/" + component + "/" + deviceId, name, "live").then((data) => {
            let newValue = data?.[0]?.[1]
            let newTimestamp = data?.[0]?.[0]

            if (newValue !== undefined) {
                valueStore.dispatch({
                    type: "update",
                    data: {[name]: newValue, timestamp: newTimestamp / 1000},
                    deviceId,
                    clientId: clientId,
                    component: component
                })
            }
        })
    }

    function loadAggregateFromDatabase(clientId, component, deviceId, columnName, interval) {
        Database.getAggregationValue(clientId + "/" + component + "/" + deviceId, interval, columnName).then((data) => {
            let newValue = data?.[columnName]

            if (newValue !== undefined) {
                valueStore.dispatch({
                    type: "update",
                    data: {[columnName + "__" + interval]: newValue},
                    deviceId,
                    clientId: clientId,
                    component: component
                })
            }
        })
    }


    function onConsoleLog(callback) {
        store.subscribe(() => {
            let state = store.getState()
            if (state.type === "console") {
                callback(state)
            }
        })
    }

    function onValueChange(callback) {
        return valueStore.subscribe(() => {
            let state = valueStore.getState()
            callback(state)
        })
    }

    function getValue(clientId, component, deviceId, name) {

        let state = valueStore.getState()
        return state?.[clientId]?.[component]?.[deviceId]?.[name]

    }

    function onData(callback) {
        return store.subscribe(() => {
            let state = store.getState()
            if (state.type === "data") {
                callback(state)
            }
        })
    }

    return {
        init: init,
        remove: remove,
        onData: onData,
        onValueChange: onValueChange,
        getValue: getValue,
        loadFromDatabase: loadFromDatabase,
        loadAggregateFromDatabase: loadAggregateFromDatabase
    }
}()


export {
    IotCore
};