import React, {Component} from 'react';
import {PubSub} from "@aws-amplify/pubsub";
import {default as AnsiUp} from 'ansi_up';
import {Auth} from "aws-amplify";
import {Loggin} from "../APIClient";
import InfiniteScroll from "react-infinite-scroll-component";
import {Transition} from "react-transition-group";
import {IoTelescopeOutline} from "react-icons/io5";
import availableClients from "../Clients";

const ansi_up = new AnsiUp();

const transitonStyle = {
    entering: " opacity-0 -translate-y-10",
    entered: " opacity-100 translate-y-0",
    exiting: " translate-y-0",
    exited: " translate-y-0",
};

class Console extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consoleArray: [],
            currentSelectedClients: availableClients[0],
            availableClients: availableClients,
            isDrawerOpen: false

        }
        this.scrollbarRef = React.createRef()
    }

    fetchData() {
        Loggin.get(this.state.currentSelectedClients.clientId, 0).then((data) => {
            if (data !== undefined) {

                this.setState({consoleArray: data})
                // scroll to the end of the timeline
                if (this.scrollbarRef.current !== null) {
                    this.scrollbarRef.current.scrollTop = 4000
                }
            }
        })
    }

    componentDidMount() {
        this.fetchData()
        Auth.currentCredentials().then((info) => {
            const cognitoIdentityId = info.identityId;
            let allAvailableClients = this.state.availableClients.map(item => item.deviceId).filter(function (item, pos, self) {
                return self.indexOf(item) == pos;
            }).map(item => 'installation/' + item)
            PubSub.subscribe(allAvailableClients).subscribe({
                next: data => {
                    if (data.value.hasOwnProperty("typ")) {
                        if (data.value.typ === "console") {
                            if (data.value.clientId === this.state.currentSelectedClients.clientId) {
                                this.setState((state) => {
                                    let consoleArray = state.consoleArray
                                    consoleArray = [data.value, ...consoleArray]
                                    return ({consoleArray})
                                })
                                if ((this.scrollbarRef?.current?.scrollTop || -400) === 0) {
                                    this.scrollbarRef.current.scrollTop = 40000
                                }
                            }
                        }
                    } else {

                        this.setState({readings: data.value})
                    }
                },
                error: error => {

                    if (error.error.errorCode === 8) {
                        this.setState({error: "Die verbindung mit dem IOT broker wurde abgehend der nutzer verfügt nicht über die nötigen berechtigungen "})

                    }
                    console.log('error');
                    console.error(error);
                },
                close: () => console.log('Done'),
            });
        });


    }

    loadNextChunk() {
        Loggin.get(this.state.currentSelectedClients.clientId, this.state.consoleArray.length).then((data) => {
            if (data !== undefined) {
                this.setState((state) => ({
                    consoleArray: ([...data, ...state.consoleArray].sort(function (x, y) {
                        return y.timestamp - x.timestamp;
                    }))
                }))
            }
        })
    }

    render() {

        return (
            <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">Console</h1>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="py-4">

                        <div className="bg-white shadow mb-3 w-full">
                            <div className="px-4 py-5 sm:px-6 hover:bg-slate-50 cursor-pointer" onClick={() => {
                                this.setState((state) => ({isDrawerOpen: !state.isDrawerOpen}))
                            }}>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {this.state.currentSelectedClients.clientName}
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    {this.state.currentSelectedClients.clientDescription}
                                </p>
                            </div>
                            <Transition in={this.state.isDrawerOpen} timeout={100} unmountOnExit={true}>
                                {state => (<div
                                    className={("p-3 transition-all ease-in-out duration-100 " + transitonStyle[state])}>


                                    <fieldset>

                                        <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                            {this.state.availableClients.map(device => {
                                                let isCurrent = device.clientId === this.state.currentSelectedClients.clientId

                                                return (<label
                                                    className="relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none hover:bg-slate-50"
                                                    onClick={() => {
                                                        this.setState({
                                                            currentSelectedClients: device,
                                                            isDrawerOpen: false,
                                                            consoleArray: []
                                                        }, () => {
                                                            this.fetchData()

                                                        })

                                                    }}>
                                                    <input type="radio" name="project-type" defaultValue="Newsletter"
                                                           className="sr-only" aria-labelledby="project-type-0-label"
                                                           aria-describedby="project-type-0-description-0 project-type-0-description-1"/>
                                                    <div className="flex-1 flex">
                                                        <div className="flex flex-col">
                                                            <div id="project-type-0-label"
                                                                 className="block text-sm font-medium text-gray-900">
                                                                {device.clientName}
                                                            </div>
                                                            <div id="project-type-0-description-0"
                                                                 className="mt-1 flex items-center text-sm text-gray-500">
                                                                {device.clientDescription}
                                                            </div>
                                                            <div id="project-type-0-description-1"
                                                                 className="mt-6 text-sm font-medium text-gray-900">
                                                                {device.clientLocation}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <svg
                                                        className={("h-5 w-5 text-indigo-600 " + (isCurrent ? ("") : ("invisible")))}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                              clipRule="evenodd"/>
                                                    </svg>
                                                    <div
                                                        className={"absolute -inset-px rounded-lg border-2 pointer-events-none " + (isCurrent ? ("border") : ("border-2"))}
                                                        aria-hidden="true"/>
                                                </label>)
                                            })}

                                        </div>
                                    </fieldset>
                                </div>)}
                            </Transition>

                        </div>


                        <div className="border-4  border-gray-200 rounded-lg ">

                            <div className={"h-96 overflow-y-scroll customScrollbar font-mono text-sm"}
                                 ref={this.scrollbarRef} id="console">
                                <InfiniteScroll
                                    dataLength={this.state.consoleArray.length} //This is important field to render the next data
                                    next={() => {
                                        this.loadNextChunk()
                                    }}
                                    scrollableTarget="console"
                                    className={" flex flex-col-reverse "}
                                    inverse={true}
                                    hasMore={true}
                                    loader={<h4 style={{textAlign: 'center'}}>Loading...</h4>}
                                    endMessage={
                                        <p style={{textAlign: 'center'}}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }

                                >
                                    {this.state.consoleArray.length === 0 && (

                                        <div
                                            className="max-w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className="space-y-1 text-center">
                                                <IoTelescopeOutline className="mx-auto h-12 w-12 text-gray-400"/>
                                                <div className="flex text-sm text-gray-600">
                                                    <p className="pl-1">The log seems to be empty </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.consoleArray.map(row => {
                                        // console.log(row.timestamp);
                                        // console.log(new Date(row.timestamp * 1000).toUTCString());
                                        let date = new Date(row.timestamp * 1000);

                                        let month = date.getMonth() + 1;
                                        let day = date.getDate();
                                        let hour = date.getHours().toString().padStart(2, "0")
                                        let min = date.getMinutes().toString().padStart(2, "0")
                                        let sec = date.getSeconds().toString().padStart(2, "0")
                                        let year = date.getFullYear()


                                        return <div className={"hover:bg-gray-50"}>
                                            <div
                                                dangerouslySetInnerHTML={{__html: ansi_up.ansi_to_html(day + "." + month + "." + year + "  " + hour + ":" + min + ":" + sec + " " + row.message)}}/>
                                        </div>
                                    })}
                                </InfiniteScroll>

                            </div>


                            <div className={"space-x-2 h-11 inline-flex  align-middle p-2 shadow"}>
                                <button type="button" onClick={async () => {
                                    await PubSub.publish('installation/' + this.state.currentSelectedClients.clientId + '/VCC', {
                                            typ: "command",
                                            command: "dischargeBattery",
                                            options: {durationInMin: 5, power: 1000, phase: 1}
                                        }
                                    )
                                    ;

                                }}
                                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    dischargeBattery
                                </button>
                                <button type="button" onClick={async () => {
                                    await PubSub.publish('installation/' + this.state.currentSelectedClients.clientId + '/VCC', {
                                        typ: "command",
                                        command: "chargeBattery",
                                        options: {durationInMin: 5, power: 1000}
                                    });
                                }}
                                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    chargeBattery
                                </button>
                                <button type="button" onClick={async () => {
                                    await PubSub.publish('installation/' + this.state.currentSelectedClients.clientId + '/VCC', {
                                        typ: "command",
                                        command: "batteryStop",
                                    });
                                }}
                                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    batteryStop
                                </button>

                                <button type="button" onClick={async () => {
                                    await PubSub.publish('installation/' + this.state.currentSelectedClients.clientId + '/VCC', {
                                        typ: "command",
                                        command: "batteryStop",
                                        shellCommand: "",
                                        password: "ereneo-edige-client-efa47d9a"
                                    });
                                }}
                                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Command
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

Console.propTypes = {};

export default Console;