import React, {Component} from 'react';
import PropTypes from "prop-types";

class CloudIcon extends Component {
    render() {
        return (
            <>


                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                     width={this.props.width} height={this.props.height} viewBox="-0.5 -0.5 48 33"
                     content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-06-29T06:47:53.318Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;1FyrnWQYXlBPSM8bdY46&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;BJ6WKatnVGpccqFuapEV&quot; name=&quot;Page-1&quot;>jZPbboMwDIafhstVQNbRXq603UGdNKnadp2SQLIGjEJoYU+/UMxpVaVxgeLPv41jG4eEafWkaS7egHHl+C6rHLJ2fN8LPPtuQI1gPm9BoiVDNIC9/OEIXaSlZLyYCA2AMjKfwgiyjEdmwqjWcJ7KYlDTr+Y04VdgH1F1Tb8kM6KlCz8Y+DOXiei+7D0sW09KOzHepBCUwXmEyMYhoQYw7SmtQq6a3nV9aeO2N7x9YZpn5j8B6/CzjAXVC+2/bL53H/H8+HpH2iwnqkq8MBZr6q4DwqTKnjyHrE5cG2l7s6MHrt6hkEZCZn0HMAZSK6BKJg2IbE1cW6Aa5YpGx0RDmbEQFOhLWhJfnlHSR4w1kFtaGA3HvuWkJ6MM2+UqCPzG03XWtQajheAMDevJm2ukVdJs50xCEcyk3ZViFikorWyFDbBF8OpmZ71+XnbPOaTc6NpKMIC4OOL6j30eNuYe116MloWgjOKOJn3mYYz2gJPszGFjLr7Rb0c2vw==</diagram></mxfile>"
                >
                    <defs/>
                    <g>
                        <path
                            d="M 40.22 31 C 43.6 30.49 46 26.61 45.6 22.34 C 45.19 18.07 42.13 15.03 38.76 15.54 C 38.72 9.16 35.19 3.67 30.26 2.34 C 25.34 1 20.34 4.18 18.24 9.97 C 16.68 7.72 14.21 6.87 11.97 7.82 C 9.73 8.76 8.15 11.32 7.98 14.3 C 4.33 14.13 1.27 17.73 1.14 22.34 C 1 26.95 3.85 30.83 7.49 31 Z"
                            fill="rgb(255, 255, 255)" stroke="#f9b772" strokeWidth={3} strokeMiterlimit={10}
                            pointerEvents="all"/>
                    </g>
                </svg>


            </>
        );
    }
}

CloudIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};

export default CloudIcon;