import React, {Component} from 'react';
import PropTypes from 'prop-types';
import availableDevices from "../Devices";
import Select from "../ERPImport/Select";
import ControlGraphicElement from "../components/ControlGraphicElement";
import {Settings} from "../APIClient";
import DisplayValue from "../components/DisplayValue";
import MeasuringPoint from "../circuitSymbols/MeasuringPoint";
import Graph from "../components/Graph";
import {Transition} from "react-transition-group";
import TimeWindowSelection from "../components/TimeWindowSelection";

const transitonStyle = {
    entering: " opacity-0 -translate-y-10",
    entered: " opacity-100 translate-y-0",
    exiting: " translate-y-0",
    exited: " translate-y-0",
};

class ControlGraphic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedControlTypes: {consumer: "", generator: "", storage: ""},
            settings: {}, isDrawerOpen: false

        }
    }


    componentDidMount() {
        Settings.get().then((data) => {
            this.setState({
                selectedControlTypes: {
                    consumer: data.find(item => (item.settingName === "consumer" && item.settingScope === "orchestration")).settingValue,
                    generator: data.find(item => (item.settingName === "generator" && item.settingScope === "orchestration")).settingValue,
                    storage: data.find(item => (item.settingName === "storage" && item.settingScope === "orchestration")).settingValue
                }
            })
        })
    }

    render() {
        let selectableStorage = availableDevices.filter((item) => (item.controlType === "storage")).map(item => ({
            ...item,
            value: item.deviceId,
            label: item.deviceName
        }))
        let selectableGenerator = availableDevices.filter((item) => (item.controlType === "generator")).map(item => ({
            ...item,
            value: item.deviceId,
            label: item.deviceName
        }))
        let selectableConsumer = availableDevices.filter((item) => (item.controlType === "consumer")).map(item => ({
            ...item,
            value: item.deviceId,
            label: item.deviceName
        }))

        let consumer = "{" + this.state.selectedControlTypes.consumer.replaceAll("/", ".") + ".kWL1}+{" + this.state.selectedControlTypes.consumer.replaceAll("/", ".") + ".kWL2}+{" + this.state.selectedControlTypes.consumer.replaceAll("/", ".") + ".kWL3}"
        let generator = "{" + this.state.selectedControlTypes.generator.replaceAll("/", ".") + ".kWL1}+{" + this.state.selectedControlTypes.generator.replaceAll("/", ".") + ".kWL2}+{" + this.state.selectedControlTypes.generator.replaceAll("/", ".") + ".kWL3}"
        let storage = "{" + this.state.selectedControlTypes.storage.replaceAll("/", ".") + ".kWL1}+{" + this.state.selectedControlTypes.storage.replaceAll("/", ".") + ".kWL2}+{" + this.state.selectedControlTypes.storage.replaceAll("/", ".") + ".kWL3}"
        console.log(this.state.selectedControlTypes);
        return (<div>


            <div id="viewport"
                 className="transition-transform ease-in-out duration-300 subpixel-antialiased   overflow-hidden "
                 style={{width: '1250px', height: '850px', position: 'relative', display: 'block'}}>
                <div className="absolute pointer-events-none" style={{transform: 'translate(195px, 0px)'}}>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                         width="890px" height="890px" viewBox="-0.5 -0.5 403 323"
                         content="<mxfile host=&quot;Electron&quot; modified=&quot;2022-09-12T08:21:13.120Z&quot; agent=&quot;5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) draw.io/16.5.1 Chrome/96.0.4664.110 Electron/16.0.7 Safari/537.36&quot; etag=&quot;O8W5g_nsktaRH4eDC3m-&quot; version=&quot;16.5.1&quot; type=&quot;device&quot;><diagram id=&quot;J5xOdDazILJoCMNmCmJO&quot; name=&quot;Page-1&quot;>5ZRNT4QwEIZ/DUcTaFdkjy6uejEx2cNGbw0doaYwpJRl119vsYXCEuNHvHmifZh5p/NOIaBpebxTrC4ekIMMSMiPAb0JCIloQs2jJydHyCq2JFeCO+bBTryBg6GjreDQzAI1otSinsMMqwoyPWNMKezmYS8o51VrlsMC7DIml3QvuC4sTciV5/cg8mKoHMVr+6ZkQ7DrpCkYx26C6DagqULUdlUeU5C9e4MvNu/2k7fjwRRU+jsJKYh1Ds/tpn5NcC+TqHuqL5zKgcnWNewOq0+DA1Dx695Is6uwMnBT6FKaXWSWCtuKQ18hNDubC3xhqT9jNHZu7gxgCVqdTEjnvb10fhUTWwemQDItDnN55kacj3JjhUcUpjAJ3X0kodNxt3GVhHOJBluVgcuaenkmFH8lpJnKQS+EzGLStkcfo/rB2Mi/GtvqzO34t2M7F6LxX43NbP2XbMP9D5Fu3wE=</diagram></mxfile>"
                         style={{backgroundColor: 'rgb(255, 255, 255)'}}>
                        <defs/>
                        <g>
                            <path d="M 0 120 L 400 120" fill="none" stroke="rgb(105,105,105)" strokeMiterlimit={10}
                                  pointerEvents="stroke"/>
                            <path d="M 200 220 L 200 70" fill="none" stroke="rgb(105,105,105)" strokeMiterlimit={10}
                                  pointerEvents="stroke"/>
                            <ellipse cx={200} cy={120} rx={10} ry={10} fill="rgb(255, 255, 255)"
                                     stroke="rgb(105,105,105)"
                                     pointerEvents="all"/>
                        </g>
                    </svg>
                </div>
                <div className="absolute opacity-100" style={{transform: 'translate(561px, 184px)'}}>
                    {(this.state.selectedControlTypes.consumer !== "" && this.state.selectedControlTypes.generator !== "" && this.state.selectedControlTypes.storage !== "") && (<>
                        <MeasuringPoint scale={"0.5"} text={"v. Grid"} link={"#"}
                                        value={`(${storage} ) + (${generator})  + (${consumer})   `}/>
                    </>)}
                </div>
                <div className="absolute opacity-100" style={{transform: 'translate(486px, 520px)'}}>
                    <ControlGraphicElement options={selectableStorage} titel={"Speicher"}
                                           value={this.state.selectedControlTypes.storage}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   let selectedControlTypes = state.selectedControlTypes
                                                   selectedControlTypes.storage = value?.value || undefined
                                                   return ({selectedControlTypes})
                                               })
                                               Settings.setSetting(value?.value || undefined, 'storage', 'orchestration')
                                           }}
                    />
                </div>
                <div className="absolute opacity-100" style={{transform: 'translate(12px, 190px)'}}>
                    <ControlGraphicElement options={selectableGenerator} titel={"Erzeuger"}
                                           value={this.state.selectedControlTypes.generator}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   let selectedControlTypes = state.selectedControlTypes
                                                   selectedControlTypes.generator = value?.value || undefined
                                                   return ({selectedControlTypes})
                                               })
                                               Settings.setSetting(value?.value || undefined, 'generator', 'orchestration')

                                           }}
                    />

                </div>
                <div className="absolute opacity-100" style={{transform: 'translate(948px, 190px)'}}>
                    <ControlGraphicElement options={selectableConsumer} titel={"Verbraucher"}
                                           value={this.state.selectedControlTypes.consumer}
                                           onChange={(value) => {
                                               this.setState((state) => {
                                                   let selectedControlTypes = state.selectedControlTypes
                                                   selectedControlTypes.consumer = value?.value || undefined
                                                   return ({selectedControlTypes})
                                               })
                                               Settings.setSetting(value?.value || undefined, 'consumer', 'orchestration')

                                           }}
                    />

                </div>
                {/*<div className="absolute opacity-100" style={{transform: 'translate(577px, 209px)'}}>*/}
                {/*    <div className="flex flex-row items-center justify-center "*/}
                {/*         style={{width: '93px', height: '52px'}}>*/}
                {/*        <div className="font-medium"> Werte &gt;</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="absolute opacity-100" style={{transform: 'translate(403px, 411px)'}}>*/}
                {/*    <div>*/}
                {/*        <div className="flex-shrink font-mono ">*/}
                {/*            <DisplayValue*/}
                {/*                value={"{" + this.state.selectedControlTypes.consumer.replaceAll("/", ".") + ".kWL1} + {" + this.state.selectedControlTypes.consumer.replaceAll("/", ".") + ".kWL2} + {" + this.state.selectedControlTypes.consumer.replaceAll("/", ".") + ".kWL3}"}/> W*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="absolute opacity-100" style={{transform: 'translate(774px, 409px)'}}>*/}
                {/*    <div>*/}
                {/*        <div className="flex-shrink font-mono ">NaN kW 2</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="absolute opacity-100" style={{transform: 'translate(593px, 263px)'}}>*/}
                {/*    <div>*/}
                {/*        <DisplayValue*/}
                {/*            value={`${consumer} + ${generator} + ${storage}  `}/> W*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="bg-white shadow mb-3 w-full">
                <div className="px-4 py-5 sm:px-6 hover:bg-slate-50 cursor-pointer" onClick={() => {
                    this.setState((state) => ({isDrawerOpen: !state.isDrawerOpen}))
                }}>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Graphic
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Graphic to check the performance of the orchestration
                    </p>
                </div>
                <Transition in={this.state.isDrawerOpen} timeout={100} unmountOnExit={true}>
                    {state => (<div
                        className={("p-3 transition-all ease-in-out duration-100 " + transitonStyle[state])}>


                        <fieldset>

                            <div className="w-full h-full">

                                <div
                                    className="col-span-12 row-span-6 bg-white overflow-hidden shadow-lg border-2 border-gray-200 rounded-md divide-y divide-gray-200">
                                    <div className="px-2 py-3 sm:px-6">
                                        <div className="md:flex md:items-center md:justify-between">
                                            <div className="flex-1 min-w-0">
                                            </div>
                                            <div className="mt-4 flex md:mt-0 md:ml-2">
                                                <TimeWindowSelection/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 pt-5 ">
                                        {(this.state.selectedControlTypes.consumer !== "" && this.state.selectedControlTypes.generator !== "" && this.state.selectedControlTypes.storage !== "") && (<>
                                            <Graph interval={"24h"}
                                                   graphAxis={[{
                                                       name: "generator",
                                                       yAxis: 2,
                                                       visible: true,
                                                       column: generator.replaceAll("/", "."),
                                                       color: "#1de90e",
                                                       standardInterval: "1h"
                                                   }, {
                                                       name: "consumer",
                                                       yAxis: 2,
                                                       visible: true,
                                                       column: consumer.replaceAll("/", "."),
                                                       color: "#1eeacb",
                                                       standardInterval: "1h"
                                                   }, {
                                                       name: "storage",
                                                       yAxis: 2,
                                                       visible: true,
                                                       column: storage.replaceAll("/", "."),
                                                       color: "#0ea5e9",
                                                       standardInterval: "1h"
                                                   }, {
                                                       name: "virtual grid",
                                                       yAxis: 2,
                                                       visible: true,
                                                       column: "(" + storage.replaceAll("/", ".") + ")+(" + generator.replaceAll("/", ".") + ")" + "+(" + consumer.replaceAll("/", ".") + ")",
                                                       color: "#ff00d3",
                                                       standardInterval: "1h"
                                                   }]}
                                                   cangeCount={1}/>


                                        </>)}
                                    </div>
                                </div>


                            </div>
                        </fieldset>
                    </div>)}
                </Transition>

            </div>

        </div>);
    }
}

ControlGraphic.propTypes = {};

export default ControlGraphic;