import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "../ERPImport/Select";
import DisplayValue from "./DisplayValue";
import {TbPlugConnectedX, TbPlugX, TbSwitchHorizontal} from "react-icons/tb";
import {IoCloseOutline} from "react-icons/io5";
import {Settings} from "../APIClient";

class ControlGraphicElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false
        }
    }



    render() {
        let selectedValue = this.props.options.filter(item => item.value === this.props.value)[0]

        let deviceString = (selectedValue?.deviceId || "").replaceAll("/", ".")

        return (
            <div>
                <div
                    className="    rounded-md p-4 border-2 border-gray-200 bg-white w-72 h-72"
                >
                    <div className="bg-white     ">
                        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap ">
                            <div className="ml-4 mt-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{this.props.titel}</h3>
                                <p className="mt-1 text-sm text-gray-500">{selectedValue?.deviceName || ""}</p>
                            </div>
                            <div className="ml-4 mt-4 flex-shrink-0">
                                {(selectedValue?.deviceId !== undefined) && (<button type="button"
                                                                                     className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-gray-500 hover:bg-gray-200 focus:outline-none "
                                                                                     onClick={() => {
                                                                                         this.setState((state) => ({isEditMode: !state.isEditMode}))
                                                                                     }}>
                                    {this.state.isEditMode ? (<IoCloseOutline className="h-5 w-5"/>) : (
                                        <TbSwitchHorizontal className="h-5 w-5"/>)}
                                </button>)}
                            </div>
                        </div>
                        <div className={"mt-2 space-y-2"}>
                            {this.state.isEditMode ? (<div>

                                <p> Wähle Sie ein Gerät.</p>
                                <Select
                                    className={"grow"}
                                    width={"100%"}
                                    value={selectedValue}
                                    onChange={event => {
                                        if (this.props.onChange !== undefined) {
                                            this.props.onChange(event)
                                        }
                                        this.setState({isEditMode: false})

                                    }}
                                    options={this.props.options}
                                />

                            </div>) : (<div>
                                {(selectedValue?.deviceId !== undefined) ? (<div>
                                    <ul role="list" className="divide-y divide-gray-200 mt-4">
                                        <li className="relative bg-white py-4 px-3 hover:bg-gray-50">
                                            <div className="flex justify-between space-x-3">
                                                <div className="min-w-0 flex-1">
                                                    <p className="text-sm font-medium text-gray-900 truncate">Gesamtleistung:</p>
                                                </div>
                                                <div
                                                    className="flex-shrink-0 whitespace-nowrap text-base  text-orange-800 font-mono">
                                                    <DisplayValue
                                                        value={"{" + deviceString + ".kWL1} + {" + deviceString + ".kWL2} + {" + deviceString + ".kWL3}"}/> W
                                                </div>
                                            </div>
                                        </li>
                                        {/*<li className="relative bg-white py-4 px-3 hover:bg-gray-50">*/}
                                        {/*    <div className="flex justify-between space-x-3">*/}
                                        {/*        <div className="min-w-0 flex-1">*/}
                                        {/*            <p className="text-sm font-medium text-gray-900 truncate">Phase*/}
                                        {/*                2:</p>*/}
                                        {/*        </div>*/}
                                        {/*        <div*/}
                                        {/*            className="flex-shrink-0 whitespace-nowrap text-base  text-teal-800 font-mono">*/}
                                        {/*            <DisplayValue*/}
                                        {/*                value={"{" + deviceString + ".kWL2}"}/> W*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}
                                        {/*<li className="relative bg-white py-4 px-3 hover:bg-gray-50">*/}
                                        {/*    <div className="flex justify-between space-x-3">*/}
                                        {/*        <div className="min-w-0 flex-1">*/}
                                        {/*            <p className="text-sm font-medium text-gray-900 truncate">Phase*/}
                                        {/*                3:</p>*/}
                                        {/*        </div>*/}
                                        {/*        <div*/}
                                        {/*            className="flex-shrink-0 whitespace-nowrap text-base  text-slate-800 font-mono">*/}
                                        {/*            <DisplayValue*/}
                                        {/*                value={"{" + deviceString + ".kWL3}"}/> W*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}

                                    </ul>
                                </div>) : (<div>
                                    <div className="text-center mt-5">
                                        <TbPlugX className="mx-auto h-12 w-12 text-gray-400"/>
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">Nichts gewählt</h3>
                                        <p className="mt-1 text-sm text-gray-500">Wählen Sie eine Gerät für diese
                                            Function aus. </p>
                                        <div className="mt-6">
                                            <button type="button" onClick={() => {
                                                this.setState((state) => ({isEditMode: !state.isEditMode}))
                                            }}
                                                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                {/*<TbPlugConnectedX className="-ml-1 mr-2 h-5 w-5"/>*/}
                                                Gerät wählen
                                            </button>
                                        </div>
                                    </div>

                                </div>)}


                            </div>)}


                        </div>
                    </div>

                </div>


            </div>
        );
    }
}

ControlGraphicElement.propTypes = {};

export default ControlGraphicElement;