import React, {Component} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';

/**
 * This component displays a chart
 *  @param {null} options - I dont't why
 *  @param {string} divice - can be null
 *  @param {int} diviceId - Id of the device
 *  @param {list} layout - an array with the dementions [{w: 3, h: 12},{w: 1, h: 3}]
 *  @param {int} ownId - the id ove the layout z.b. 0 or 1..
 *  @param {string} interval - sets the intervall of the chart
 *  @param {list} axes - Input of all the info about the chart
 *  @param {int} cangeCount - just a counter to rerender
 *  @example
 *  <Chart options={this.props.options} divice={""} diviceId={1}
 *  layout={[{w: 3, h: 12}]} ownId={0} interval={[{w: 3, h: 12},{w: 1, h: 3}]}
 *  axes={this.state.pageStructure}
 *  cangeCount={1}/>
 */
class Chart extends Component {
    constructor(props) {
        super(props);
        this.chartComponent = React.createRef();

        this.state = {
            chartOptions: {

                chart: {
                    displayErrors: true,
                    zoomType: 'x',
                    style: {
                        fontFamily: 'Roboto'
                    },
                    events: {
                        load() {
                            this.showLoading('<img alt={"loading"} id="spinner" style="    max-width: 10%;" src="https://portal.ereneo.de/images/loading.gif"/>');

                        }


                    }
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        overflow: 'justify'
                    },

                },
                tooltip: {

                    crosshairs: {


                        dashStyle: 'solid'
                    },
                },
                yAxis: [{ //Axis 0 Leistung 1
                    labels: {
                        format: '{value} W',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    title: {
                        text: 'Leistung',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    lineWidth: 0.5,
                    opposite: true,
                    visible: props.axes.map(x => x.yAxis === 0).includes(true)

                }, { //Axis 1 Leistung 2
                    labels: {
                        format: '{value} W',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    title: {
                        text: 'Leistung',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    lineWidth: 0.5,
                    opposite: false,
                    visible: props.axes.map(x => x.yAxis === 1).includes(true)


                }, { //Axis 2 Energie 1
                    gridLineWidth: 0,
                    title: {
                        text: 'Energie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} kWh',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 2).includes(true),
                    opposite: true
                }, { //Axis 3 Energie 2
                    gridLineWidth: 0,
                    title: {
                        text: 'Energie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} kWh',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 3).includes(true),
                    opposite: false
                }, { //Axis 4 Autarkie
                    gridLineWidth: 0,
                    title: {
                        text: 'Autarkie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} %',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 4).includes(true),
                    opposite: false
                }, { //Axis 5 Eigenverbrauch
                    gridLineWidth: 0,
                    title: {
                        text: 'Eigenverbrauch',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} %',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 5).includes(true),

                    opposite: false
                }, { //Axis 6 Frequenz
                    gridLineWidth: 0,
                    title: {
                        text: 'Frequenz',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} Hz',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 6).includes(true),

                    opposite: false
                }, { //Axis 7 Spannung
                    gridLineWidth: 0,
                    title: {
                        text: 'Spannung',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} V',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 7).includes(true),
                    opposite: false
                }, { //Axis 8 Strom
                    gridLineWidth: 0,
                    title: {
                        text: 'Strom',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} A',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 8).includes(true),

                    opposite: false
                }, { //Axis 9 Ladezustand
                    gridLineWidth: 0,
                    min: 0,
                    max: 100,
                    title: {
                        text: 'Ladezustand',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} %',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 9).includes(true),

                    opposite: false
                }, { //Axis 10 VEBus
                    gridLineWidth: 0,

                    title: {
                        text: 'VE State',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    categories: ['Off', 'Low Power', 'Fault', 'Bulk', 'Absorption', 'Float', 'Storage', 'Equalize', 'Passthru', 'Inverting', 'Power assist', 'Power supply', 'Bulk protect'],
                    min: 0,
                    max: 12,
                    lineWidth: 0.5,
                    visible: props.axes.map(x => x.yAxis === 10).includes(true),

                    opposite: false
                }


                ],
                legend: {
                    enabled: false
                },
                colors: ['#3498db', '#e67e22', '#2ecc71', '#34495e', '#aaeeee', '#9b59b6',
                    '#e74c3c', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
                plotOptions: {
                    columnrange: {
                        dataLabels: {
                            enabled: true,
                            format: '{y}°C'
                        }
                    },
                    series: {
                        states: {
                            inactive: {
                                opacity: 0.99
                            }
                        }
                    },
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 1,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                                [1, Highcharts.getOptions().colors[2]],
                            ]
                        },
                        marker: {
                            radius: 1
                        },
                        lineWidth: 0.5,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: 0
                    }
                },

                series: props.axes,

            },
            hoverData: null,
            lastDevice: null,
            interval: "24h",
            intervalName: "",
            timeDropdownOpend: false,
            graphData: {},
            itsLoading: false,
            axes: null,
            ownId: this.props.ownId,
            deviceId: parseInt(this.props.diviceId),

        };
        Boost(Highcharts);
        Highcharts.setOptions(
            {

                global: {
                    useUTC: false
                }

            });
    }

    componentDidMount() {
        this.setState({
            chartOptions: {
                series: this.props.axes
            }
        });
        const chart = this.chartComponent.current.chart;
        let shiftFlag = false;
        this.chartComponent.current.chart.reflow();

        (this.state.chartOptions.series !== undefined) ? Object.keys(this.state.chartOptions.series).map(key => (
            shiftFlag = chart.series[key].data.length > 40 || shiftFlag
            //,this.liveaxes = store.subscribe(() => chart.series[key].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, parseFloat(store.getState().websocketData[this.state.deviceId][this.state.chartOptions.series[key]["colum"]])], true, shiftFlag))
        )) : (console.log())


        //this.axesOne = store.subscribe(() => chart.series[0].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, (Math.round((parseFloat(store.getState().websocketData[this.state.deviceId][this.state.chartOptions.series[0]["colum"]]) + parseFloat(store.getState().websocketData[this.state.deviceId]["kWL2"]) + parseFloat(store.getState().websocketData[this.state.deviceId]["kWL3"]) * 10) / 10))], true, shiftFlag));
        //this.axesTow = store.subscribe(() => chart.series[1].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, parseFloat(store.getState().websocketData[this.state.deviceId][this.state.chartOptions.series[1]["colum"]])], true, shiftFlag));
        //this.axesthree = store.subscribe(() => chart.series[2].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, parseFloat(store.getState().websocketData[this.state.deviceId][this.state.chartOptions.series[2]["colum"]])], true, shiftFlag))
    }

    componentWillUnmount() {
        Object.keys(this.state.chartOptions.series).map(key => (
            this.liveaxes
        ));
        this.setState(null);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let finalwidth;
        let finalheight;
        const width = this.props.layout["w"];
        const height = this.props.layout["h"];

        switch (width) {
            case 1:
                finalwidth = 514;
                break;
            case 2:
                finalwidth = 1050;
                break;
            case 3:
                finalwidth = 1620;
                break;
            default:
                console.info("The chart container size could not be matched [width] ")
                finalwidth = 410;
                break;
        }
        switch (height) {
            case 6:
                finalheight = 185;
                break;
            case 7:
                finalheight = 220;
                break;
            case 8:
                finalheight = 270;
                break;
            case 9:
                finalheight = 320;
                break;
            case 10:
                finalheight = 368;
                break;
            case 11:
                finalheight = 400;
                break;
            case 12:
                finalheight = 450;
                break;
            default:
                console.info("The chart container size could not be matched [height] ")

                finalheight = 185;
                break;
        }
        this.chartComponent.current.chart.setSize(finalwidth, finalheight);
        if (this.props.axes !== this.state.axes) {
            this.setState({axes: this.props.axes});

            const chart = this.state.chartOptions;
            chart.series = this.props.axes;
            this.setState({
                chartOptions: chart
            });
            this.setState({itsLoading: true});
        }
        this.chartComponent.current.chart.reflow()
    }

    updateSeries = (data, axes) => {
        let chart = this.state.chartOptions.series;
        chart[axes].data = data;
        this.setState({
            chartOptions: {
                series: chart
            }
        });
    };


    updateData = (axes) => {
        if (this.state.chartOptions.series !== undefined) {
            const colum = this.state.chartOptions.series[axes].colum;
            let interval;
            if (this.props.interval === "") {
                interval = this.state.chartOptions.series[axes].standardInterval;
            } else {
                interval = this.props.interval;
            }
            const table = (this.state.chartOptions.series[axes].table === null) ? ("") : (this.state.chartOptions.series[axes].table);
            let tableprefix = table.endsWith("/d") ? ((interval === "1j") ? ("/d/c") : ("")) : (((interval === "1m" || interval === "1j" || interval === "1w") ? ("/a") : ("")));

            const withoutresolution = this.state.chartOptions.series[axes].withoutresolution;
            fetch('https://api.ereneo.com/V1/Rest_API_Test?name=' + table + tableprefix + '&column=' + colum + '&interval=' + interval + '&withoutresolution=' + withoutresolution)
                .then(res => res.json())
                .then((data) => {
                    this.updateSeries(data, axes);
                    this.chartComponent.current.chart.hideLoading();
                })
                .catch(console.log);
        }
    };

    render() {

        if (this.props.divice !== this.state.lastDevice) {
            this.setState({lastDevice: this.props.divice});

            Object.keys(this.state.chartOptions.series).map(key =>
                this.updateData(key)
            );


            this.setState({intervalName: "Auswahl Zeitfenster", timeDropdownOpend: false});
        }
        if (this.props.interval !== this.state.interval) {
            this.setState({interval: this.props.interval});
            Object.keys(this.state.chartOptions.series).map(key =>
                this.updateData(key)
            );
            this.setState({intervalName: "Auswahl Zeitfenster", timeDropdownOpend: false});
        }
        return (
            <>
                <HighchartsReact
                    style={{display: "inline-block"}}
                    {...this.props}
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                    ref={this.chartComponent}
                />
            </>
        )
    }
}

export default Chart;